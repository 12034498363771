import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import './helpers/i18n'
import theme from './helpers/theme'
import { store } from './redux/store'

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.querySelector('#root'),
)
