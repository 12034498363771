/* tslint:disable */
/* eslint-disable */
/**
 * GrowthEco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    Tfsa: 'TFSA',
    Rrsp: 'RRSP',
    Resp: 'RESP',
    Lira: 'LIRA',
    Lif: 'LIF',
    Rrif: 'RRIF',
    NonRegisteredCad: 'NON_REGISTERED_CAD',
    NonRegisteredUsd: 'NON_REGISTERED_USD',
    Margin: 'MARGIN',
    NonRegisteredBusiness: 'NON_REGISTERED_BUSINESS'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActionType = {
    Add: 'ADD',
    Update: 'UPDATE',
    Delete: 'DELETE'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];


/**
 * 
 * @export
 * @interface Advisor
 */
export interface Advisor {
    /**
     * 
     * @type {number}
     * @memberof Advisor
     */
    'id': number;
    /**
     * 
     * @type {ProfileType}
     * @memberof Advisor
     */
    'profileType'?: ProfileType;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    'lastName'?: string;
    /**
     * 
     * @type {Locale}
     * @memberof Advisor
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof Advisor
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof Advisor
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof Advisor
     */
    'roles'?: Array<RoleType>;
    /**
     * 
     * @type {Array<AdvisorType>}
     * @memberof Advisor
     */
    'types'?: Array<AdvisorType>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Advisor
     */
    'clientIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Advisor
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Advisor
     */
    'admin'?: boolean;
}


/**
 * 
 * @export
 * @interface AdvisorPermissionsPut
 */
export interface AdvisorPermissionsPut {
    /**
     * 
     * @type {InsurancePermissionsPut}
     * @memberof AdvisorPermissionsPut
     */
    'insurancePermissions': InsurancePermissionsPut;
    /**
     * 
     * @type {MortgagePermissionsPut}
     * @memberof AdvisorPermissionsPut
     */
    'mortgagePermissions': MortgagePermissionsPut;
    /**
     * 
     * @type {NotificationPermissionsPut}
     * @memberof AdvisorPermissionsPut
     */
    'notificationPermissions': NotificationPermissionsPut;
    /**
     * 
     * @type {DocumentPermissionsPut}
     * @memberof AdvisorPermissionsPut
     */
    'documentPermissions': DocumentPermissionsPut;
    /**
     * 
     * @type {ClientPermissionsPut}
     * @memberof AdvisorPermissionsPut
     */
    'clientPermissions': ClientPermissionsPut;
}
/**
 * 
 * @export
 * @interface AdvisorPost
 */
export interface AdvisorPost {
    /**
     * 
     * @type {string}
     * @memberof AdvisorPost
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorPost
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorPost
     */
    'lastName': string;
    /**
     * 
     * @type {Locale}
     * @memberof AdvisorPost
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof AdvisorPost
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof AdvisorPost
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Array<AdvisorType>}
     * @memberof AdvisorPost
     */
    'types'?: Array<AdvisorType>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdvisorPost
     */
    'clientIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorPost
     */
    'admin'?: boolean;
}


/**
 * 
 * @export
 * @interface AdvisorPut
 */
export interface AdvisorPut {
    /**
     * 
     * @type {string}
     * @memberof AdvisorPut
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorPut
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorPut
     */
    'lastName': string;
    /**
     * 
     * @type {Locale}
     * @memberof AdvisorPut
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof AdvisorPut
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof AdvisorPut
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorPut
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<AdvisorType>}
     * @memberof AdvisorPut
     */
    'types'?: Array<AdvisorType>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdvisorPut
     */
    'clientIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorPut
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorPut
     */
    'admin'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AdvisorType = {
    Investment: 'INVESTMENT',
    Insurance: 'INSURANCE',
    Mortgage: 'MORTGAGE',
    Accounting: 'ACCOUNTING'
} as const;

export type AdvisorType = typeof AdvisorType[keyof typeof AdvisorType];


/**
 * 
 * @export
 * @interface AppointmentEmailPost
 */
export interface AppointmentEmailPost {
    /**
     * 
     * @type {ProductType}
     * @memberof AppointmentEmailPost
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof AppointmentEmailPost
     */
    'availableDate': string | number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentEmailPost
     */
    'availableTimeSlot': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentEmailPost
     */
    'availableDate2': string | number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentEmailPost
     */
    'availableTimeSlot2': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentEmailPost
     */
    'additionalInformation'?: string;
}


/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    /**
     * 
     * @type {AdvisorType}
     * @memberof Assignment
     */
    'advisorType'?: AdvisorType;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    'advisorId'?: number;
}


/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {number}
     * @memberof CalendarEvent
     */
    'id': number;
    /**
     * 
     * @type {EventType}
     * @memberof CalendarEvent
     */
    'type'?: EventType;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'description'?: string;
}


/**
 * 
 * @export
 * @interface CheckSpecimenUpload
 */
export interface CheckSpecimenUpload extends DocumentUpload {
}


/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    'id': number;
    /**
     * 
     * @type {ProfileType}
     * @memberof Client
     */
    'profileType'?: ProfileType;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'lastName'?: string;
    /**
     * 
     * @type {Locale}
     * @memberof Client
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof Client
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof Client
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof Client
     */
    'roles'?: Array<RoleType>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'crmCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof Client
     */
    'maritalStatus'?: MaritalStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof Client
     */
    'financialObjectives'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Client
     */
    'advisorIds'?: Array<number>;
    /**
     * 
     * @type {Array<InsurancePolicy>}
     * @memberof Client
     */
    'insurancePolicies'?: Array<InsurancePolicy>;
    /**
     * 
     * @type {Array<Mortgage>}
     * @memberof Client
     */
    'mortgages'?: Array<Mortgage>;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    'admin'?: boolean;
}


/**
 * 
 * @export
 * @interface ClientPermissions
 */
export interface ClientPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissions
     */
    'createContactInformation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissions
     */
    'updateContactInformation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissions
     */
    'updateProfile'?: boolean;
}
/**
 * 
 * @export
 * @interface ClientPermissionsPut
 */
export interface ClientPermissionsPut {
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissionsPut
     */
    'createContactInformation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissionsPut
     */
    'updateContactInformation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPermissionsPut
     */
    'updateProfile'?: boolean;
}
/**
 * 
 * @export
 * @interface ClientPost
 */
export interface ClientPost {
    /**
     * 
     * @type {string}
     * @memberof ClientPost
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPost
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPost
     */
    'lastName': string;
    /**
     * 
     * @type {Locale}
     * @memberof ClientPost
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof ClientPost
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof ClientPost
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPost
     */
    'crmCode': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPost
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof ClientPost
     */
    'maritalStatus'?: MaritalStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientPost
     */
    'financialObjectives'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClientPost
     */
    'advisorIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface ClientPut
 */
export interface ClientPut {
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'lastName': string;
    /**
     * 
     * @type {Locale}
     * @memberof ClientPut
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof ClientPut
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof ClientPut
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'crmCode': string;
    /**
     * 
     * @type {string}
     * @memberof ClientPut
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof ClientPut
     */
    'maritalStatus'?: MaritalStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientPut
     */
    'financialObjectives'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClientPut
     */
    'advisorIds': Array<number>;
    /**
     * 
     * @type {Array<InsurancePolicyPut>}
     * @memberof ClientPut
     */
    'insurancePolicies'?: Array<InsurancePolicyPut>;
    /**
     * 
     * @type {Array<MortgagePut>}
     * @memberof ClientPut
     */
    'mortgages'?: Array<MortgagePut>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPut
     */
    'active'?: boolean;
}


/**
 * 
 * @export
 * @interface ClientSearchQuery
 */
export interface ClientSearchQuery {
    /**
     * 
     * @type {ClientSearchQueryCriteria}
     * @memberof ClientSearchQuery
     */
    'search'?: ClientSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchQuery
     */
    'sortBy'?: ClientSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof ClientSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof ClientSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientSearchQuery
     */
    'pageSize'?: number;
}

export const ClientSearchQuerySortByEnum = {
    Email: 'email',
    FirstName: 'firstName',
    LastName: 'lastName',
    Id: 'id'
} as const;

export type ClientSearchQuerySortByEnum = typeof ClientSearchQuerySortByEnum[keyof typeof ClientSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface ClientSearchQueryCriteria
 */
export interface ClientSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof ClientSearchQueryCriteria
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchQueryCriteria
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchQueryCriteria
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchQueryCriteria
     */
    'textSearch'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClientSearchQueryCriteria
     */
    'advisorIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSearchQueryCriteria
     */
    'isOrphan'?: boolean;
}
/**
 * 
 * @export
 * @interface ClientSearchResult
 */
export interface ClientSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof ClientSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof ClientSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof ClientSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<ClientSearchResultContent>}
     * @memberof ClientSearchResult
     */
    'content': Array<ClientSearchResultContent>;
}
/**
 * 
 * @export
 * @interface ClientSearchResultContent
 */
export interface ClientSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof ClientSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {ProfileType}
     * @memberof ClientSearchResultContent
     */
    'profileType': ProfileType;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchResultContent
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchResultContent
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchResultContent
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSearchResultContent
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClientSearchResultContent
     */
    'advisorIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientSearchResultContent
     */
    'advisorNames'?: Array<string>;
    /**
     * 
     * @type {Array<AdvisorType>}
     * @memberof ClientSearchResultContent
     */
    'advisorTypes'?: Array<AdvisorType>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSearchResultContent
     */
    'active'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Direction = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];


/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    'documentType'?: DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof Document
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'contentType': string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'clientId': number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'url'?: string;
}


/**
 * 
 * @export
 * @interface DocumentPermissions
 */
export interface DocumentPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateInsurancePolicyDocuments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateMortgageStatements'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateT1s'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateT4s'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateRRSPContributions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateNoticeOfAssessments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateFinancialPlans'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateTestaments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateDriversLicences'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateCheckSpecimens'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updatePowerOfAttorneys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissions
     */
    'updateGeneralDocuments'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentPermissionsPut
 */
export interface DocumentPermissionsPut {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateInsurancePolicyDocuments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateMortgageStatements'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateT1s'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateT4s'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateRRSPContributions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateNoticeOfAssessments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateFinancialPlans'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateTestaments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateDriversLicences'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateCheckSpecimens'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updatePowerOfAttorneys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPermissionsPut
     */
    'updateGeneralDocuments'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentSearchQuery
 */
export interface DocumentSearchQuery {
    /**
     * 
     * @type {DocumentSearchQueryCriteria}
     * @memberof DocumentSearchQuery
     */
    'search'?: DocumentSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQuery
     */
    'sortBy'?: DocumentSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof DocumentSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof DocumentSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSearchQuery
     */
    'pageSize'?: number;
}

export const DocumentSearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type DocumentSearchQuerySortByEnum = typeof DocumentSearchQuerySortByEnum[keyof typeof DocumentSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface DocumentSearchQueryCriteria
 */
export interface DocumentSearchQueryCriteria {
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentSearchQueryCriteria
     */
    'documentType'?: DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof DocumentSearchQueryCriteria
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchQueryCriteria
     */
    'textSearch'?: string;
}


/**
 * 
 * @export
 * @interface DocumentSearchResult
 */
export interface DocumentSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof DocumentSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof DocumentSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof DocumentSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<DocumentSearchResultContent>}
     * @memberof DocumentSearchResult
     */
    'content': Array<DocumentSearchResultContent>;
}
/**
 * 
 * @export
 * @interface DocumentSearchResultContent
 */
export interface DocumentSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof DocumentSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentSearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof DocumentSearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSearchResultContent
     */
    'metadata'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    InsurancePolicy: 'INSURANCE_POLICY',
    MortgageStatement: 'MORTGAGE_STATEMENT',
    NoticeOfAssessment: 'NOTICE_OF_ASSESSMENT',
    T4: 'T4',
    T1: 'T1',
    CheckSpecimen: 'CHECK_SPECIMEN',
    DriversLicense: 'DRIVERS_LICENSE',
    Testament: 'TESTAMENT',
    PowerOfAttorney: 'POWER_OF_ATTORNEY',
    FinancialPlan: 'FINANCIAL_PLAN',
    RrspContribution: 'RRSP_CONTRIBUTION',
    General: 'GENERAL'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface DocumentUpload
 */
export interface DocumentUpload {
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentUpload
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpload
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface DriversLicenseUploadPost
 */
export interface DriversLicenseUploadPost extends DocumentUpload {
}


/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {ErrorType}
     * @memberof ErrorMessage
     */
    'code'?: ErrorType;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'timestamp'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorType = {
    OauthClientInitFailed: 'OAUTH_CLIENT_INIT_FAILED',
    Auth0Error: 'AUTH0_ERROR',
    Auth0UserCreationFailed: 'AUTH0_USER_CREATION_FAILED',
    Auth0UserDeletionFailed: 'AUTH0_USER_DELETION_FAILED',
    Auth0RedirectUrlRequestFailed: 'AUTH0_REDIRECT_URL_REQUEST_FAILED',
    Auth0UserUpdateFailed: 'AUTH0_USER_UPDATE_FAILED',
    Auth0UserNotExist: 'AUTH0_USER_NOT_EXIST',
    Auth0UserAssignRolesFailed: 'AUTH0_USER_ASSIGN_ROLES_FAILED',
    Auth0RoleNotFound: 'AUTH0_ROLE_NOT_FOUND',
    Auth0UserGetFailed: 'AUTH0_USER_GET_FAILED',
    Auth0UserGetRolesFailed: 'AUTH0_USER_GET_ROLES_FAILED',
    Auth0MaxRetryReached: 'AUTH0_MAX_RETRY_REACHED',
    Auth0SendResetPasswordFailed: 'AUTH0_SEND_RESET_PASSWORD_FAILED',
    Auth0PermissionsGetFailed: 'AUTH0_PERMISSIONS_GET_FAILED',
    Auth0ResetPasswordLinkGenerationFailed: 'AUTH0_RESET_PASSWORD_LINK_GENERATION_FAILED',
    Auth0GetLogsFailed: 'AUTH0_GET_LOGS_FAILED',
    ProfileMissingEmail: 'PROFILE_MISSING_EMAIL',
    ProfileAlreadyExists: 'PROFILE_ALREADY_EXISTS',
    ProfileDeactivateReasonRequired: 'PROFILE_DEACTIVATE_REASON_REQUIRED',
    ProfileUpdateEmailNotAllowed: 'PROFILE_UPDATE_EMAIL_NOT_ALLOWED',
    ProfilePermissionLocked: 'PROFILE_PERMISSION_LOCKED',
    ClientNotAssignedToAdvisor: 'CLIENT_NOT_ASSIGNED_TO_ADVISOR',
    ClientUpdateNotAllowed: 'CLIENT_UPDATE_NOT_ALLOWED',
    ClientUpdateAdvisorsNotAllowed: 'CLIENT_UPDATE_ADVISORS_NOT_ALLOWED',
    ClientCreateOtherAdvisorsNotAllowed: 'CLIENT_CREATE_OTHER_ADVISORS_NOT_ALLOWED',
    ClientCreateContactInfoNotAllowed: 'CLIENT_CREATE_CONTACT_INFO_NOT_ALLOWED',
    ClientUpdateContactInfoNotAllowed: 'CLIENT_UPDATE_CONTACT_INFO_NOT_ALLOWED',
    ClientCreateProfileNotAllowed: 'CLIENT_CREATE_PROFILE_NOT_ALLOWED',
    ClientUpdateProfileNotAllowed: 'CLIENT_UPDATE_PROFILE_NOT_ALLOWED',
    ClientUpdateInsurancePolicyNotAllowed: 'CLIENT_UPDATE_INSURANCE_POLICY_NOT_ALLOWED',
    ClientUpdateMortgageNotAllowed: 'CLIENT_UPDATE_MORTGAGE_NOT_ALLOWED',
    ClientDeleteMortgageNotAllowed: 'CLIENT_DELETE_MORTGAGE_NOT_ALLOWED',
    ClientUpdateStatusNotAllowed: 'CLIENT_UPDATE_STATUS_NOT_ALLOWED',
    ClientCreateMortgageExists: 'CLIENT_CREATE_MORTGAGE_EXISTS',
    ClientUpdateMortgageNotFound: 'CLIENT_UPDATE_MORTGAGE_NOT_FOUND',
    ClientUploadDocumentsNotAllowed: 'CLIENT_UPLOAD_DOCUMENTS_NOT_ALLOWED',
    ClientUploadDocumentTypeNotAllowed: 'CLIENT_UPLOAD_DOCUMENT_TYPE_NOT_ALLOWED',
    ClientUploadMimeTypeNotAllowed: 'CLIENT_UPLOAD_MIME_TYPE_NOT_ALLOWED',
    ClientSearchDocumentsNotAllowed: 'CLIENT_SEARCH_DOCUMENTS_NOT_ALLOWED',
    ClientSearchMortgagesNotAllowed: 'CLIENT_SEARCH_MORTGAGES_NOT_ALLOWED',
    ClientSearchNotificationsNotAllowed: 'CLIENT_SEARCH_NOTIFICATIONS_NOT_ALLOWED',
    ClientSearchAdvisorLimitExceeded: 'CLIENT_SEARCH_ADVISOR_LIMIT_EXCEEDED',
    ClientSearchCriteriaNotAllowed: 'CLIENT_SEARCH_CRITERIA_NOT_ALLOWED',
    ClientDownloadDocumentsNotAllowed: 'CLIENT_DOWNLOAD_DOCUMENTS_NOT_ALLOWED',
    ClientDeleteDocumentsNotAllowed: 'CLIENT_DELETE_DOCUMENTS_NOT_ALLOWED',
    ClientDeleteDocumentsFailed: 'CLIENT_DELETE_DOCUMENTS_FAILED',
    ClientDeleteDocumentsTypeNotAllowed: 'CLIENT_DELETE_DOCUMENTS_TYPE_NOT_ALLOWED',
    AdvisorGetAnotherNotAllowed: 'ADVISOR_GET_ANOTHER_NOT_ALLOWED',
    AdvisorUpdateAnotherNotAllowed: 'ADVISOR_UPDATE_ANOTHER_NOT_ALLOWED',
    AdvisorUpdateRolesNotAllowed: 'ADVISOR_UPDATE_ROLES_NOT_ALLOWED',
    AdvisorGetPermissionsNotAllowed: 'ADVISOR_GET_PERMISSIONS_NOT_ALLOWED',
    AdvisorInvalidReassignmentType: 'ADVISOR_INVALID_REASSIGNMENT_TYPE',
    AdvisorInvalidReassignmentClients: 'ADVISOR_INVALID_REASSIGNMENT_CLIENTS',
    MortgageNameMustBeUnique: 'MORTGAGE_NAME_MUST_BE_UNIQUE',
    UserMissingProfile: 'USER_MISSING_PROFILE',
    ValidationIdPayloadMismatch: 'VALIDATION_ID_PAYLOAD_MISMATCH',
    NotificationBroadcastNotAllowed: 'NOTIFICATION_BROADCAST_NOT_ALLOWED',
    NotificationClientNotAllowed: 'NOTIFICATION_CLIENT_NOT_ALLOWED',
    BadRequestUploadFileSizeExceeded: 'BAD_REQUEST_UPLOAD_FILE_SIZE_EXCEEDED',
    UnexpectedError: 'UNEXPECTED_ERROR',
    AccessDenied: 'ACCESS_DENIED',
    EndpointNotFound: 'ENDPOINT_NOT_FOUND',
    AuthenticationFailed: 'AUTHENTICATION_FAILED'
} as const;

export type ErrorType = typeof ErrorType[keyof typeof ErrorType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventType = {
    Holiday: 'HOLIDAY',
    Closure: 'CLOSURE'
} as const;

export type EventType = typeof EventType[keyof typeof EventType];


/**
 * 
 * @export
 * @interface FinancialPlanUploadPost
 */
export interface FinancialPlanUploadPost extends DocumentUpload {
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FrequencyType = {
    Weekly: 'WEEKLY',
    BiWeekly: 'BI_WEEKLY',
    SemiMonthly: 'SEMI_MONTHLY',
    Monthly: 'MONTHLY',
    FOO: 'BAR'
} as const;

export type FrequencyType = typeof FrequencyType[keyof typeof FrequencyType];


/**
 * 
 * @export
 * @interface GeneralDocumentUploadPost
 */
export interface GeneralDocumentUploadPost extends DocumentUpload {
}


/**
 * 
 * @export
 * @interface InsurancePermissions
 */
export interface InsurancePermissions {
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePermissions
     */
    'updateInsurancePolicy'?: boolean;
}
/**
 * 
 * @export
 * @interface InsurancePermissionsPut
 */
export interface InsurancePermissionsPut {
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePermissionsPut
     */
    'updateInsurancePolicy'?: boolean;
}
/**
 * 
 * @export
 * @interface InsurancePolicy
 */
export interface InsurancePolicy {
    /**
     * 
     * @type {number}
     * @memberof InsurancePolicy
     */
    'id': number;
    /**
     * 
     * @type {InsuranceType}
     * @memberof InsurancePolicy
     */
    'type': InsuranceType;
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePolicy
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicy
     */
    'description': string;
}


/**
 * 
 * @export
 * @interface InsurancePolicyDocumentSearchQuery
 */
export interface InsurancePolicyDocumentSearchQuery {
    /**
     * 
     * @type {InsurancePolicyDocumentSearchQueryCriteria}
     * @memberof InsurancePolicyDocumentSearchQuery
     */
    'search'?: InsurancePolicyDocumentSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQuery
     */
    'sortBy'?: InsurancePolicyDocumentSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof InsurancePolicyDocumentSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchQuery
     */
    'pageSize'?: number;
}

export const InsurancePolicyDocumentSearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    InsuranceType: 'insuranceType',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type InsurancePolicyDocumentSearchQuerySortByEnum = typeof InsurancePolicyDocumentSearchQuerySortByEnum[keyof typeof InsurancePolicyDocumentSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface InsurancePolicyDocumentSearchQueryCriteria
 */
export interface InsurancePolicyDocumentSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {InsuranceType}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'insuranceType'?: InsuranceType;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchQueryCriteria
     */
    'textSearch'?: string;
}


/**
 * 
 * @export
 * @interface InsurancePolicyDocumentSearchResult
 */
export interface InsurancePolicyDocumentSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<InsurancePolicyDocumentSearchResultContent>}
     * @memberof InsurancePolicyDocumentSearchResult
     */
    'content': Array<InsurancePolicyDocumentSearchResultContent>;
}
/**
 * 
 * @export
 * @interface InsurancePolicyDocumentSearchResultContent
 */
export interface InsurancePolicyDocumentSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {InsuranceType}
     * @memberof InsurancePolicyDocumentSearchResultContent
     */
    'insuranceType': InsuranceType;
}


/**
 * 
 * @export
 * @interface InsurancePolicyPut
 */
export interface InsurancePolicyPut {
    /**
     * 
     * @type {InsuranceType}
     * @memberof InsurancePolicyPut
     */
    'type': InsuranceType;
    /**
     * 
     * @type {boolean}
     * @memberof InsurancePolicyPut
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsurancePolicyPut
     */
    'description'?: string;
}


/**
 * 
 * @export
 * @interface InsurancePolicyUploadPost
 */
export interface InsurancePolicyUploadPost extends DocumentUpload {
    /**
     * 
     * @type {InsuranceType}
     * @memberof InsurancePolicyUploadPost
     */
    'policyType': InsuranceType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceType = {
    Life: 'LIFE',
    Disability: 'DISABILITY',
    CriticalIllness: 'CRITICAL_ILLNESS',
    Group: 'GROUP',
    Child: 'CHILD',
    Car: 'CAR',
    Home: 'HOME'
} as const;

export type InsuranceType = typeof InsuranceType[keyof typeof InsuranceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Locale = {
    En: 'en',
    Fr: 'fr'
} as const;

export type Locale = typeof Locale[keyof typeof Locale];


/**
 * 
 * @export
 * @enum {string}
 */

export const MaritalStatus = {
    Single: 'SINGLE',
    Married: 'MARRIED',
    Separated: 'SEPARATED',
    Divorced: 'DIVORCED',
    Windowed: 'WINDOWED',
    DeFactoSpouse: 'DE_FACTO_SPOUSE'
} as const;

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];


/**
 * 
 * @export
 * @interface Mortgage
 */
export interface Mortgage {
    /**
     * 
     * @type {number}
     * @memberof Mortgage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Mortgage
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Mortgage
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof Mortgage
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Mortgage
     */
    'maturityDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof Mortgage
     */
    'emailSent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Mortgage
     */
    'rate': number;
    /**
     * 
     * @type {RateType}
     * @memberof Mortgage
     */
    'rateType': RateType;
    /**
     * 
     * @type {FrequencyType}
     * @memberof Mortgage
     */
    'frequency': FrequencyType;
    /**
     * 
     * @type {number}
     * @memberof Mortgage
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof Mortgage
     */
    'homeEquityLineOfCredit': boolean;
    /**
     * 
     * @type {number}
     * @memberof Mortgage
     */
    'propertyValue': number;
    /**
     * 
     * @type {number}
     * @memberof Mortgage
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof Mortgage
     */
    'lastBalanceUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof Mortgage
     */
    'lender': string;
}


/**
 * 
 * @export
 * @interface MortgagePermissions
 */
export interface MortgagePermissions {
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePermissions
     */
    'updateMortgage'?: boolean;
}
/**
 * 
 * @export
 * @interface MortgagePermissionsPut
 */
export interface MortgagePermissionsPut {
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePermissionsPut
     */
    'updateMortgage'?: boolean;
}
/**
 * 
 * @export
 * @interface MortgagePut
 */
export interface MortgagePut {
    /**
     * 
     * @type {number}
     * @memberof MortgagePut
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgagePut
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePut
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgagePut
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgagePut
     */
    'maturityDate': string;
    /**
     * 
     * @type {number}
     * @memberof MortgagePut
     */
    'rate': number;
    /**
     * 
     * @type {RateType}
     * @memberof MortgagePut
     */
    'rateType': RateType;
    /**
     * 
     * @type {FrequencyType}
     * @memberof MortgagePut
     */
    'frequency': FrequencyType;
    /**
     * 
     * @type {number}
     * @memberof MortgagePut
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePut
     */
    'homeEquityLineOfCredit': boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePut
     */
    'propertyValue': number;
    /**
     * 
     * @type {number}
     * @memberof MortgagePut
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof MortgagePut
     */
    'lastBalanceUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof MortgagePut
     */
    'lender': string;
}


/**
 * 
 * @export
 * @interface MortgageSearchQuery
 */
export interface MortgageSearchQuery {
    /**
     * 
     * @type {MortgageSearchQueryCriteria}
     * @memberof MortgageSearchQuery
     */
    'search'?: MortgageSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQuery
     */
    'sortBy'?: MortgageSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof MortgageSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQuery
     */
    'pageSize'?: number;
}

export const MortgageSearchQuerySortByEnum = {
    Name: 'name',
    MaturityDate: 'maturityDate',
    Rate: 'rate',
    RateType: 'rateType',
    Frequency: 'frequency',
    Amount: 'amount',
    HomeEquityLineOfCredit: 'homeEquityLineOfCredit',
    PropertyValue: 'propertyValue',
    Balance: 'balance',
    LastBalanceUpdate: 'lastBalanceUpdate',
    Lender: 'lender',
    Id: 'id'
} as const;

export type MortgageSearchQuerySortByEnum = typeof MortgageSearchQuerySortByEnum[keyof typeof MortgageSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface MortgageSearchQueryCriteria
 */
export interface MortgageSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'maturityDateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'maturityDateTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQueryCriteria
     */
    'rate'?: number;
    /**
     * 
     * @type {RateType}
     * @memberof MortgageSearchQueryCriteria
     */
    'rateType'?: RateType;
    /**
     * 
     * @type {FrequencyType}
     * @memberof MortgageSearchQueryCriteria
     */
    'frequency'?: FrequencyType;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQueryCriteria
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchQueryCriteria
     */
    'homeEquityLineOfCredit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQueryCriteria
     */
    'propertyValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchQueryCriteria
     */
    'balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'lastBalanceUpdateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'lastBalanceUpdateTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'lender'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchQueryCriteria
     */
    'textSearch'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchQueryCriteria
     */
    'showDefault'?: boolean;
}


/**
 * 
 * @export
 * @interface MortgageSearchResult
 */
export interface MortgageSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof MortgageSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof MortgageSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof MortgageSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<MortgageSearchResultContent>}
     * @memberof MortgageSearchResult
     */
    'content': Array<MortgageSearchResultContent>;
}
/**
 * 
 * @export
 * @interface MortgageSearchResultContent
 */
export interface MortgageSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchResultContent
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResultContent
     */
    'visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchResultContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchResultContent
     */
    'maturityDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResultContent
     */
    'emailSent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchResultContent
     */
    'rate': number;
    /**
     * 
     * @type {RateType}
     * @memberof MortgageSearchResultContent
     */
    'rateType': RateType;
    /**
     * 
     * @type {FrequencyType}
     * @memberof MortgageSearchResultContent
     */
    'frequency': FrequencyType;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchResultContent
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageSearchResultContent
     */
    'homeEquityLineOfCredit': boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchResultContent
     */
    'propertyValue': number;
    /**
     * 
     * @type {number}
     * @memberof MortgageSearchResultContent
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchResultContent
     */
    'lastBalanceUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof MortgageSearchResultContent
     */
    'lender': string;
}


/**
 * 
 * @export
 * @interface MortgageStatementSearchQuery
 */
export interface MortgageStatementSearchQuery {
    /**
     * 
     * @type {MortgageStatementSearchQueryCriteria}
     * @memberof MortgageStatementSearchQuery
     */
    'search'?: MortgageStatementSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQuery
     */
    'sortBy'?: MortgageStatementSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof MortgageStatementSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchQuery
     */
    'pageSize'?: number;
}

export const MortgageStatementSearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    MortgageName: 'mortgageName',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type MortgageStatementSearchQuerySortByEnum = typeof MortgageStatementSearchQuerySortByEnum[keyof typeof MortgageStatementSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface MortgageStatementSearchQueryCriteria
 */
export interface MortgageStatementSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'mortgageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'mortgageName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchQueryCriteria
     */
    'textSearch'?: string;
}
/**
 * 
 * @export
 * @interface MortgageStatementSearchResult
 */
export interface MortgageStatementSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof MortgageStatementSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof MortgageStatementSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof MortgageStatementSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageStatementSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageStatementSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageStatementSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<MortgageStatementSearchResultContent>}
     * @memberof MortgageStatementSearchResult
     */
    'content': Array<MortgageStatementSearchResultContent>;
}
/**
 * 
 * @export
 * @interface MortgageStatementSearchResultContent
 */
export interface MortgageStatementSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof MortgageStatementSearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof MortgageStatementSearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementSearchResultContent
     */
    'mortgageId': number;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementSearchResultContent
     */
    'mortgageName': string;
}


/**
 * 
 * @export
 * @interface MortgageStatementUploadPost
 */
export interface MortgageStatementUploadPost extends DocumentUpload {
    /**
     * 
     * @type {number}
     * @memberof MortgageStatementUploadPost
     */
    'mortgageId': number;
    /**
     * 
     * @type {string}
     * @memberof MortgageStatementUploadPost
     */
    'mortgageName'?: string;
}


/**
 * 
 * @export
 * @interface NoticeOfAssessmentSearchQuery
 */
export interface NoticeOfAssessmentSearchQuery {
    /**
     * 
     * @type {NoticeOfAssessmentSearchQueryCriteria}
     * @memberof NoticeOfAssessmentSearchQuery
     */
    'search'?: NoticeOfAssessmentSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQuery
     */
    'sortBy'?: NoticeOfAssessmentSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof NoticeOfAssessmentSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchQuery
     */
    'pageSize'?: number;
}

export const NoticeOfAssessmentSearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    FiscalYear: 'fiscalYear',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type NoticeOfAssessmentSearchQuerySortByEnum = typeof NoticeOfAssessmentSearchQuerySortByEnum[keyof typeof NoticeOfAssessmentSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface NoticeOfAssessmentSearchQueryCriteria
 */
export interface NoticeOfAssessmentSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'fiscalYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchQueryCriteria
     */
    'textSearch'?: string;
}
/**
 * 
 * @export
 * @interface NoticeOfAssessmentSearchResult
 */
export interface NoticeOfAssessmentSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<NoticeOfAssessmentSearchResultContent>}
     * @memberof NoticeOfAssessmentSearchResult
     */
    'content': Array<NoticeOfAssessmentSearchResultContent>;
}
/**
 * 
 * @export
 * @interface NoticeOfAssessmentSearchResultContent
 */
export interface NoticeOfAssessmentSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentSearchResultContent
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface NoticeOfAssessmentUploadPost
 */
export interface NoticeOfAssessmentUploadPost extends DocumentUpload {
    /**
     * 
     * @type {number}
     * @memberof NoticeOfAssessmentUploadPost
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface NotificationPermissions
 */
export interface NotificationPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationPermissions
     */
    'sendNotifications'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationPermissionsPut
 */
export interface NotificationPermissionsPut {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationPermissionsPut
     */
    'sendNotifications'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationPost
 */
export interface NotificationPost {
    /**
     * 
     * @type {number}
     * @memberof NotificationPost
     */
    'toProfileId'?: number;
    /**
     * 
     * @type {ProductType}
     * @memberof NotificationPost
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationPost
     */
    'isBroadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationPost
     */
    'message'?: string;
}


/**
 * 
 * @export
 * @interface NotificationSearchQuery
 */
export interface NotificationSearchQuery {
    /**
     * 
     * @type {NotificationSearchQueryCriteria}
     * @memberof NotificationSearchQuery
     */
    'search'?: NotificationSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQuery
     */
    'sortBy'?: NotificationSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof NotificationSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchQuery
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchQuery
     */
    'isSetResultAsRead'?: boolean;
}

export const NotificationSearchQuerySortByEnum = {
    FromProfileId: 'fromProfileId',
    CreatedDate: 'createdDate',
    ProductType: 'productType',
    FromProfileFullName: 'fromProfileFullName',
    ToProfileFullName: 'toProfileFullName',
    Id: 'id'
} as const;

export type NotificationSearchQuerySortByEnum = typeof NotificationSearchQuerySortByEnum[keyof typeof NotificationSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface NotificationSearchQueryCriteria
 */
export interface NotificationSearchQueryCriteria {
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchQueryCriteria
     */
    'fromProfileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQueryCriteria
     */
    'fromProfileFullName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchQueryCriteria
     */
    'toProfileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQueryCriteria
     */
    'toProfileFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQueryCriteria
     */
    'createdDateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQueryCriteria
     */
    'createdDateTo'?: string;
    /**
     * 
     * @type {ProductType}
     * @memberof NotificationSearchQueryCriteria
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchQueryCriteria
     */
    'isBroadcast'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchQueryCriteria
     */
    'isRead'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchQueryCriteria
     */
    'message'?: string;
}


/**
 * 
 * @export
 * @interface NotificationSearchResult
 */
export interface NotificationSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof NotificationSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof NotificationSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof NotificationSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<NotificationSearchResultContent>}
     * @memberof NotificationSearchResult
     */
    'content': Array<NotificationSearchResultContent>;
}
/**
 * 
 * @export
 * @interface NotificationSearchResultContent
 */
export interface NotificationSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchResultContent
     */
    'fromProfileId': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchResultContent
     */
    'fromProfileFullName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationSearchResultContent
     */
    'toProfileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchResultContent
     */
    'toProfileFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchResultContent
     */
    'createdDate': string;
    /**
     * 
     * @type {ProductType}
     * @memberof NotificationSearchResultContent
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchResultContent
     */
    'isBroadcast': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSearchResultContent
     */
    'isRead': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationSearchResultContent
     */
    'message'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PACActionType = {
    UniqueContribution: 'UNIQUE_CONTRIBUTION',
    Create: 'CREATE',
    Update: 'UPDATE',
    Stop: 'STOP',
    Suspend: 'SUSPEND'
} as const;

export type PACActionType = typeof PACActionType[keyof typeof PACActionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PACFrequencyType = {
    Once: 'ONCE',
    Weekly: 'WEEKLY',
    BiWeekly: 'BI_WEEKLY',
    SemiMonthly: 'SEMI_MONTHLY',
    Monthly: 'MONTHLY',
    FOO: 'BAR'
} as const;

export type PACFrequencyType = typeof PACFrequencyType[keyof typeof PACFrequencyType];


/**
 * 
 * @export
 * @interface PACRequestEmailPost
 */
export interface PACRequestEmailPost {
    /**
     * 
     * @type {PACActionType}
     * @memberof PACRequestEmailPost
     */
    'actionType': PACActionType | "";
    /**
     * 
     * @type {AccountType}
     * @memberof PACRequestEmailPost
     */
    'accountType': AccountType | "";
    /**
     * 
     * @type {number}
     * @memberof PACRequestEmailPost
     */
    'amount': number;
    /**
     * 
     * @type {PACFrequencyType}
     * @memberof PACRequestEmailPost
     */
    'frequency': PACFrequencyType | '';
    /**
     * 
     * @type {string}
     * @memberof PACRequestEmailPost
     */
    'effectiveStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof PACRequestEmailPost
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @interface PortfolioRequestEmailPost
 */
export interface PortfolioRequestEmailPost {
    /**
     * 
     * @type {ActionType}
     * @memberof PortfolioRequestEmailPost
     */
    'actionType': ActionType | '';
    /**
     * 
     * @type {AccountType}
     * @memberof PortfolioRequestEmailPost
     */
    'accountType': AccountType| '';
    /**
     * 
     * @type {PortfolioType}
     * @memberof PortfolioRequestEmailPost
     */
    'portfolioType': PortfolioType| '';
    /**
     * 
     * @type {TimeHorizon}
     * @memberof PortfolioRequestEmailPost
     */
    'timeHorizon': TimeHorizon| '';
    /**
     * 
     * @type {string}
     * @memberof PortfolioRequestEmailPost
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PortfolioType = {
    Secure: 'SECURE',
    Balanced: 'BALANCED',
    ModerateGrowth: 'MODERATE_GROWTH',
    Growth: 'GROWTH',
    Action: 'ACTION'
} as const;

export type PortfolioType = typeof PortfolioType[keyof typeof PortfolioType];


/**
 * 
 * @export
 * @interface PowerOfAttorneyUploadPost
 */
export interface PowerOfAttorneyUploadPost extends DocumentUpload {
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProductType = {
    Investment: 'INVESTMENT',
    Insurance: 'INSURANCE',
    Mortgage: 'MORTGAGE',
    Accounting: 'ACCOUNTING',
    General: 'GENERAL'
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];


/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * 
     * @type {ProfileType}
     * @memberof Profile
     */
    'profileType'?: ProfileType;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'lastName'?: string;
    /**
     * 
     * @type {Locale}
     * @memberof Profile
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof Profile
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof Profile
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof Profile
     */
    'roles'?: Array<RoleType>;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'admin'?: boolean;
}


/**
 * 
 * @export
 * @interface ProfilePermissions
 */
export interface ProfilePermissions {
    /**
     * 
     * @type {InsurancePermissions}
     * @memberof ProfilePermissions
     */
    'insurancePermissions': InsurancePermissions;
    /**
     * 
     * @type {MortgagePermissions}
     * @memberof ProfilePermissions
     */
    'mortgagePermissions': MortgagePermissions;
    /**
     * 
     * @type {NotificationPermissions}
     * @memberof ProfilePermissions
     */
    'notificationPermissions': NotificationPermissions;
    /**
     * 
     * @type {DocumentPermissions}
     * @memberof ProfilePermissions
     */
    'documentPermissions': DocumentPermissions;
    /**
     * 
     * @type {ClientPermissions}
     * @memberof ProfilePermissions
     */
    'clientPermissions': ClientPermissions;
}
/**
 * 
 * @export
 * @interface ProfileSearchQuery
 */
export interface ProfileSearchQuery {
    /**
     * 
     * @type {ProfileSearchQueryCriteria}
     * @memberof ProfileSearchQuery
     */
    'search'?: ProfileSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchQuery
     */
    'sortBy'?: ProfileSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof ProfileSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof ProfileSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileSearchQuery
     */
    'pageSize'?: number;
}

export const ProfileSearchQuerySortByEnum = {
    Email: 'email',
    FirstName: 'firstName',
    LastName: 'lastName',
    Id: 'id'
} as const;

export type ProfileSearchQuerySortByEnum = typeof ProfileSearchQuerySortByEnum[keyof typeof ProfileSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface ProfileSearchQueryCriteria
 */
export interface ProfileSearchQueryCriteria {
    /**
     * 
     * @type {ProfileType}
     * @memberof ProfileSearchQueryCriteria
     */
    'profileType'?: ProfileType;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchQueryCriteria
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchQueryCriteria
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchQueryCriteria
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchQueryCriteria
     */
    'textSearch'?: string;
}


/**
 * 
 * @export
 * @interface ProfileSearchResult
 */
export interface ProfileSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof ProfileSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof ProfileSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof ProfileSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<ProfileSearchResultContent>}
     * @memberof ProfileSearchResult
     */
    'content': Array<ProfileSearchResultContent>;
}
/**
 * 
 * @export
 * @interface ProfileSearchResultContent
 */
export interface ProfileSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof ProfileSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {ProfileType}
     * @memberof ProfileSearchResultContent
     */
    'profileType': ProfileType;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchResultContent
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchResultContent
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchResultContent
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileSearchResultContent
     */
    'inactiveReason'?: string;
    /**
     * 
     * @type {Array<AdvisorType>}
     * @memberof ProfileSearchResultContent
     */
    'types'?: Array<AdvisorType>;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSearchResultContent
     */
    'active'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProfileType = {
    Client: 'CLIENT',
    Advisor: 'ADVISOR'
} as const;

export type ProfileType = typeof ProfileType[keyof typeof ProfileType];


/**
 * 
 * @export
 * @interface QuestionEmailPost
 */
export interface QuestionEmailPost {
    /**
     * 
     * @type {ProductType}
     * @memberof QuestionEmailPost
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof QuestionEmailPost
     */
    'question': string;
}


/**
 * 
 * @export
 * @interface RRSPContributionSearchQuery
 */
export interface RRSPContributionSearchQuery {
    /**
     * 
     * @type {RRSPContributionSearchQueryCriteria}
     * @memberof RRSPContributionSearchQuery
     */
    'search'?: RRSPContributionSearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQuery
     */
    'sortBy'?: RRSPContributionSearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof RRSPContributionSearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchQuery
     */
    'pageSize'?: number;
}

export const RRSPContributionSearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    FiscalYear: 'fiscalYear',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type RRSPContributionSearchQuerySortByEnum = typeof RRSPContributionSearchQuerySortByEnum[keyof typeof RRSPContributionSearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface RRSPContributionSearchQueryCriteria
 */
export interface RRSPContributionSearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'fiscalYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchQueryCriteria
     */
    'textSearch'?: string;
}
/**
 * 
 * @export
 * @interface RRSPContributionSearchResult
 */
export interface RRSPContributionSearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof RRSPContributionSearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof RRSPContributionSearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof RRSPContributionSearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof RRSPContributionSearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RRSPContributionSearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RRSPContributionSearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<RRSPContributionSearchResultContent>}
     * @memberof RRSPContributionSearchResult
     */
    'content': Array<RRSPContributionSearchResultContent>;
}
/**
 * 
 * @export
 * @interface RRSPContributionSearchResultContent
 */
export interface RRSPContributionSearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof RRSPContributionSearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof RRSPContributionSearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RRSPContributionSearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionSearchResultContent
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface RRSPContributionUploadPost
 */
export interface RRSPContributionUploadPost extends DocumentUpload {
    /**
     * 
     * @type {number}
     * @memberof RRSPContributionUploadPost
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RateType = {
    Fix: 'FIX',
    Variable: 'VARIABLE'
} as const;

export type RateType = typeof RateType[keyof typeof RateType];


/**
 * 
 * @export
 * @interface ReassignClients
 */
export interface ReassignClients {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReassignClients
     */
    'clientIds'?: Array<number>;
    /**
     * 
     * @type {Array<Assignment>}
     * @memberof ReassignClients
     */
    'newAssignments'?: Array<Assignment>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleType = {
    New: 'NEW',
    Admin: 'ADMIN',
    Advisor: 'ADVISOR',
    Client: 'CLIENT'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];


/**
 * 
 * @export
 * @interface ServerStatus
 */
export interface ServerStatus {
    /**
     * 
     * @type {string}
     * @memberof ServerStatus
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ServerStatus
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface SimpleClientPut
 */
export interface SimpleClientPut {
    /**
     * 
     * @type {string}
     * @memberof SimpleClientPut
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleClientPut
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleClientPut
     */
    'lastName': string;
    /**
     * 
     * @type {Locale}
     * @memberof SimpleClientPut
     */
    'locale': Locale;
    /**
     * 
     * @type {Locale}
     * @memberof SimpleClientPut
     */
    'communicationLocale': Locale;
    /**
     * Phone number
     * @type {string}
     * @memberof SimpleClientPut
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleClientPut
     */
    'crmCode': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleClientPut
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof SimpleClientPut
     */
    'maritalStatus'?: MaritalStatus | '';
    /**
     * 
     * @type {Array<string>}
     * @memberof SimpleClientPut
     */
    'financialObjectives'?: Array<string>;
    /**
     * 
     * @type {Array<MortgagePut>}
     * @memberof SimpleClientPut
     */
    'mortgages'?: Array<MortgagePut>;
}


/**
 * 
 * @export
 * @interface T1SearchQuery
 */
export interface T1SearchQuery {
    /**
     * 
     * @type {T1SearchQueryCriteria}
     * @memberof T1SearchQuery
     */
    'search'?: T1SearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQuery
     */
    'sortBy'?: T1SearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof T1SearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof T1SearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof T1SearchQuery
     */
    'pageSize'?: number;
}

export const T1SearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    FiscalYear: 'fiscalYear',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type T1SearchQuerySortByEnum = typeof T1SearchQuerySortByEnum[keyof typeof T1SearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface T1SearchQueryCriteria
 */
export interface T1SearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof T1SearchQueryCriteria
     */
    'fiscalYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchQueryCriteria
     */
    'textSearch'?: string;
}
/**
 * 
 * @export
 * @interface T1SearchResult
 */
export interface T1SearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof T1SearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof T1SearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof T1SearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof T1SearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof T1SearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof T1SearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<T1SearchResultContent>}
     * @memberof T1SearchResult
     */
    'content': Array<T1SearchResultContent>;
}
/**
 * 
 * @export
 * @interface T1SearchResultContent
 */
export interface T1SearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof T1SearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof T1SearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof T1SearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof T1SearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof T1SearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof T1SearchResultContent
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface T1UploadPost
 */
export interface T1UploadPost extends DocumentUpload {
    /**
     * 
     * @type {number}
     * @memberof T1UploadPost
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface T4SearchQuery
 */
export interface T4SearchQuery {
    /**
     * 
     * @type {T4SearchQueryCriteria}
     * @memberof T4SearchQuery
     */
    'search'?: T4SearchQueryCriteria;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQuery
     */
    'sortBy'?: T4SearchQuerySortByEnum;
    /**
     * 
     * @type {Direction}
     * @memberof T4SearchQuery
     */
    'sortDir'?: Direction;
    /**
     * 
     * @type {number}
     * @memberof T4SearchQuery
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof T4SearchQuery
     */
    'pageSize'?: number;
}

export const T4SearchQuerySortByEnum = {
    DocumentType: 'documentType',
    ProductType: 'productType',
    Title: 'title',
    Filename: 'filename',
    ContentType: 'contentType',
    FiscalYear: 'fiscalYear',
    LastModifiedDate: 'lastModifiedDate',
    LastModByFullName: 'lastModByFullName',
    Id: 'id'
} as const;

export type T4SearchQuerySortByEnum = typeof T4SearchQuerySortByEnum[keyof typeof T4SearchQuerySortByEnum];

/**
 * 
 * @export
 * @interface T4SearchQueryCriteria
 */
export interface T4SearchQueryCriteria {
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof T4SearchQueryCriteria
     */
    'fiscalYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'lastModifiedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'lastModifiedTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'lastModByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchQueryCriteria
     */
    'textSearch'?: string;
}
/**
 * 
 * @export
 * @interface T4SearchResult
 */
export interface T4SearchResult {
    /**
     * The total amount of elements.
     * @type {number}
     * @memberof T4SearchResult
     */
    'totalElements': number;
    /**
     * The number of total pages.
     * @type {number}
     * @memberof T4SearchResult
     */
    'totalPages': number;
    /**
     * the number of elements currently on this page.
     * @type {number}
     * @memberof T4SearchResult
     */
    'numberOfElements': number;
    /**
     * 
     * @type {boolean}
     * @memberof T4SearchResult
     */
    'empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof T4SearchResult
     */
    'first': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof T4SearchResult
     */
    'last': boolean;
    /**
     * 
     * @type {Array<T4SearchResultContent>}
     * @memberof T4SearchResult
     */
    'content': Array<T4SearchResultContent>;
}
/**
 * 
 * @export
 * @interface T4SearchResultContent
 */
export interface T4SearchResultContent {
    /**
     * 
     * @type {number}
     * @memberof T4SearchResultContent
     */
    'id': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof T4SearchResultContent
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {ProductType}
     * @memberof T4SearchResultContent
     */
    'productType': ProductType;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof T4SearchResultContent
     */
    'clientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'lastModifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'lastModifiedByFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof T4SearchResultContent
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof T4SearchResultContent
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface T4UploadPost
 */
export interface T4UploadPost extends DocumentUpload {
    /**
     * 
     * @type {number}
     * @memberof T4UploadPost
     */
    'fiscalYear': number;
}


/**
 * 
 * @export
 * @interface TestamentUploadPost
 */
export interface TestamentUploadPost extends DocumentUpload {
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TimeHorizon = {
    _12: 'YEAR_1_2',
    _35: 'YEAR_3_5',
    _69: 'YEAR_6_9',
    _10Plus: 'YEAR_10_PLUS'
} as const;

export type TimeHorizon = typeof TimeHorizon[keyof typeof TimeHorizon];


/**
 * 
 * @export
 * @interface UploadDocumentRequest
 */
export interface UploadDocumentRequest {
    /**
     * 
     * @type {UploadDocumentRequestInfo}
     * @memberof UploadDocumentRequest
     */
    'info': UploadDocumentRequestInfo;
    /**
     * 
     * @type {File}
     * @memberof UploadDocumentRequest
     */
    'file': File;
}
/**
 * @type UploadDocumentRequestInfo
 * @export
 */
export type UploadDocumentRequestInfo = CheckSpecimenUpload | DriversLicenseUploadPost | FinancialPlanUploadPost | InsurancePolicyUploadPost | MortgageStatementUploadPost | NoticeOfAssessmentUploadPost | PowerOfAttorneyUploadPost | RRSPContributionUploadPost | T1UploadPost | T4UploadPost | TestamentUploadPost;

/**
 * 
 * @export
 * @interface WithdrawalRequestEmailPost
 */
export interface WithdrawalRequestEmailPost {
    /**
     * 
     * @type {AccountType}
     * @memberof WithdrawalRequestEmailPost
     */
    'accountType': AccountType | "";
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequestEmailPost
     */
    'amount': number;
}



/**
 * AdvisorApi - axios parameter creator
 * @export
 */
export const AdvisorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new advisor in the application and with the identity provider
         * @param {CreateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPost} advisorPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisor: async (acceptLanguage: CreateAdvisorAcceptLanguageEnum, advisorPost: AdvisorPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('createAdvisor', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'advisorPost' is not null or undefined
            assertParamExists('createAdvisor', 'advisorPost', advisorPost)
            const localVarPath = `/api/v1.0/advisor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advisorPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an advisor
         * @param {number} id 
         * @param {GetAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisor: async (id: number, acceptLanguage: GetAdvisorAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvisor', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getAdvisor', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/advisor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get permissions of an advisor
         * @param {number} id 
         * @param {GetAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorPermissions: async (id: number, acceptLanguage: GetAdvisorPermissionsAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvisorPermissions', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getAdvisorPermissions', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/advisor/{id}/permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all clients for an advisor
         * @param {number} id 
         * @param {GetAllClientsForAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientsForAdvisor: async (id: number, acceptLanguage: GetAllClientsForAdvisorAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllClientsForAdvisor', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getAllClientsForAdvisor', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/advisor/{id}/clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reassign clients of an advisor.
         * @param {number} id 
         * @param {ReassignAdvisorClientsAcceptLanguageEnum} acceptLanguage Language
         * @param {ReassignClients} reassignClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignAdvisorClients: async (id: number, acceptLanguage: ReassignAdvisorClientsAcceptLanguageEnum, reassignClients: ReassignClients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reassignAdvisorClients', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('reassignAdvisorClients', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'reassignClients' is not null or undefined
            assertParamExists('reassignAdvisorClients', 'reassignClients', reassignClients)
            const localVarPath = `/api/v1.0/advisor/{id}/clients/reassign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reassignClients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an advisor
         * @param {number} id 
         * @param {UpdateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPut} advisorPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisor: async (id: number, acceptLanguage: UpdateAdvisorAcceptLanguageEnum, advisorPut: AdvisorPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdvisor', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('updateAdvisor', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'advisorPut' is not null or undefined
            assertParamExists('updateAdvisor', 'advisorPut', advisorPut)
            const localVarPath = `/api/v1.0/advisor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advisorPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update permissions of an advisor
         * @param {number} id 
         * @param {UpdateAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPermissionsPut} advisorPermissionsPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisorPermissions: async (id: number, acceptLanguage: UpdateAdvisorPermissionsAcceptLanguageEnum, advisorPermissionsPut: AdvisorPermissionsPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdvisorPermissions', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('updateAdvisorPermissions', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'advisorPermissionsPut' is not null or undefined
            assertParamExists('updateAdvisorPermissions', 'advisorPermissionsPut', advisorPermissionsPut)
            const localVarPath = `/api/v1.0/advisor/{id}/permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advisorPermissionsPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisorApi - functional programming interface
 * @export
 */
export const AdvisorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new advisor in the application and with the identity provider
         * @param {CreateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPost} advisorPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdvisor(acceptLanguage: CreateAdvisorAcceptLanguageEnum, advisorPost: AdvisorPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Advisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdvisor(acceptLanguage, advisorPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an advisor
         * @param {number} id 
         * @param {GetAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisor(id: number, acceptLanguage: GetAdvisorAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Advisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisor(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get permissions of an advisor
         * @param {number} id 
         * @param {GetAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisorPermissions(id: number, acceptLanguage: GetAdvisorPermissionsAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisorPermissions(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all clients for an advisor
         * @param {number} id 
         * @param {GetAllClientsForAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClientsForAdvisor(id: number, acceptLanguage: GetAllClientsForAdvisorAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClientsForAdvisor(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reassign clients of an advisor.
         * @param {number} id 
         * @param {ReassignAdvisorClientsAcceptLanguageEnum} acceptLanguage Language
         * @param {ReassignClients} reassignClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignAdvisorClients(id: number, acceptLanguage: ReassignAdvisorClientsAcceptLanguageEnum, reassignClients: ReassignClients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignAdvisorClients(id, acceptLanguage, reassignClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an advisor
         * @param {number} id 
         * @param {UpdateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPut} advisorPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvisor(id: number, acceptLanguage: UpdateAdvisorAcceptLanguageEnum, advisorPut: AdvisorPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Advisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvisor(id, acceptLanguage, advisorPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update permissions of an advisor
         * @param {number} id 
         * @param {UpdateAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPermissionsPut} advisorPermissionsPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvisorPermissions(id: number, acceptLanguage: UpdateAdvisorPermissionsAcceptLanguageEnum, advisorPermissionsPut: AdvisorPermissionsPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvisorPermissions(id, acceptLanguage, advisorPermissionsPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvisorApi - factory interface
 * @export
 */
export const AdvisorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisorApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new advisor in the application and with the identity provider
         * @param {CreateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPost} advisorPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisor(acceptLanguage: CreateAdvisorAcceptLanguageEnum, advisorPost: AdvisorPost, options?: any): AxiosPromise<Advisor> {
            return localVarFp.createAdvisor(acceptLanguage, advisorPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an advisor
         * @param {number} id 
         * @param {GetAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisor(id: number, acceptLanguage: GetAdvisorAcceptLanguageEnum, options?: any): AxiosPromise<Advisor> {
            return localVarFp.getAdvisor(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get permissions of an advisor
         * @param {number} id 
         * @param {GetAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorPermissions(id: number, acceptLanguage: GetAdvisorPermissionsAcceptLanguageEnum, options?: any): AxiosPromise<ProfilePermissions> {
            return localVarFp.getAdvisorPermissions(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all clients for an advisor
         * @param {number} id 
         * @param {GetAllClientsForAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientsForAdvisor(id: number, acceptLanguage: GetAllClientsForAdvisorAcceptLanguageEnum, options?: any): AxiosPromise<Array<Client>> {
            return localVarFp.getAllClientsForAdvisor(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reassign clients of an advisor.
         * @param {number} id 
         * @param {ReassignAdvisorClientsAcceptLanguageEnum} acceptLanguage Language
         * @param {ReassignClients} reassignClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignAdvisorClients(id: number, acceptLanguage: ReassignAdvisorClientsAcceptLanguageEnum, reassignClients: ReassignClients, options?: any): AxiosPromise<Array<Client>> {
            return localVarFp.reassignAdvisorClients(id, acceptLanguage, reassignClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an advisor
         * @param {number} id 
         * @param {UpdateAdvisorAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPut} advisorPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisor(id: number, acceptLanguage: UpdateAdvisorAcceptLanguageEnum, advisorPut: AdvisorPut, options?: any): AxiosPromise<Advisor> {
            return localVarFp.updateAdvisor(id, acceptLanguage, advisorPut, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update permissions of an advisor
         * @param {number} id 
         * @param {UpdateAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
         * @param {AdvisorPermissionsPut} advisorPermissionsPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisorPermissions(id: number, acceptLanguage: UpdateAdvisorPermissionsAcceptLanguageEnum, advisorPermissionsPut: AdvisorPermissionsPut, options?: any): AxiosPromise<ProfilePermissions> {
            return localVarFp.updateAdvisorPermissions(id, acceptLanguage, advisorPermissionsPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvisorApi - object-oriented interface
 * @export
 * @class AdvisorApi
 * @extends {BaseAPI}
 */
export class AdvisorApi extends BaseAPI {
    /**
     * 
     * @summary Create a new advisor in the application and with the identity provider
     * @param {CreateAdvisorAcceptLanguageEnum} acceptLanguage Language
     * @param {AdvisorPost} advisorPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public createAdvisor(acceptLanguage: CreateAdvisorAcceptLanguageEnum, advisorPost: AdvisorPost, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).createAdvisor(acceptLanguage, advisorPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an advisor
     * @param {number} id 
     * @param {GetAdvisorAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public getAdvisor(id: number, acceptLanguage: GetAdvisorAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).getAdvisor(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get permissions of an advisor
     * @param {number} id 
     * @param {GetAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public getAdvisorPermissions(id: number, acceptLanguage: GetAdvisorPermissionsAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).getAdvisorPermissions(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all clients for an advisor
     * @param {number} id 
     * @param {GetAllClientsForAdvisorAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public getAllClientsForAdvisor(id: number, acceptLanguage: GetAllClientsForAdvisorAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).getAllClientsForAdvisor(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reassign clients of an advisor.
     * @param {number} id 
     * @param {ReassignAdvisorClientsAcceptLanguageEnum} acceptLanguage Language
     * @param {ReassignClients} reassignClients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public reassignAdvisorClients(id: number, acceptLanguage: ReassignAdvisorClientsAcceptLanguageEnum, reassignClients: ReassignClients, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).reassignAdvisorClients(id, acceptLanguage, reassignClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an advisor
     * @param {number} id 
     * @param {UpdateAdvisorAcceptLanguageEnum} acceptLanguage Language
     * @param {AdvisorPut} advisorPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public updateAdvisor(id: number, acceptLanguage: UpdateAdvisorAcceptLanguageEnum, advisorPut: AdvisorPut, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).updateAdvisor(id, acceptLanguage, advisorPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update permissions of an advisor
     * @param {number} id 
     * @param {UpdateAdvisorPermissionsAcceptLanguageEnum} acceptLanguage Language
     * @param {AdvisorPermissionsPut} advisorPermissionsPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorApi
     */
    public updateAdvisorPermissions(id: number, acceptLanguage: UpdateAdvisorPermissionsAcceptLanguageEnum, advisorPermissionsPut: AdvisorPermissionsPut, options?: AxiosRequestConfig) {
        return AdvisorApiFp(this.configuration).updateAdvisorPermissions(id, acceptLanguage, advisorPermissionsPut, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CreateAdvisorAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type CreateAdvisorAcceptLanguageEnum = typeof CreateAdvisorAcceptLanguageEnum[keyof typeof CreateAdvisorAcceptLanguageEnum];
/**
 * @export
 */
export const GetAdvisorAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetAdvisorAcceptLanguageEnum = typeof GetAdvisorAcceptLanguageEnum[keyof typeof GetAdvisorAcceptLanguageEnum];
/**
 * @export
 */
export const GetAdvisorPermissionsAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetAdvisorPermissionsAcceptLanguageEnum = typeof GetAdvisorPermissionsAcceptLanguageEnum[keyof typeof GetAdvisorPermissionsAcceptLanguageEnum];
/**
 * @export
 */
export const GetAllClientsForAdvisorAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetAllClientsForAdvisorAcceptLanguageEnum = typeof GetAllClientsForAdvisorAcceptLanguageEnum[keyof typeof GetAllClientsForAdvisorAcceptLanguageEnum];
/**
 * @export
 */
export const ReassignAdvisorClientsAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type ReassignAdvisorClientsAcceptLanguageEnum = typeof ReassignAdvisorClientsAcceptLanguageEnum[keyof typeof ReassignAdvisorClientsAcceptLanguageEnum];
/**
 * @export
 */
export const UpdateAdvisorAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type UpdateAdvisorAcceptLanguageEnum = typeof UpdateAdvisorAcceptLanguageEnum[keyof typeof UpdateAdvisorAcceptLanguageEnum];
/**
 * @export
 */
export const UpdateAdvisorPermissionsAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type UpdateAdvisorPermissionsAcceptLanguageEnum = typeof UpdateAdvisorPermissionsAcceptLanguageEnum[keyof typeof UpdateAdvisorPermissionsAcceptLanguageEnum];


/**
 * CalendarEventApi - axios parameter creator
 * @export
 */
export const CalendarEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get future calendar events (occurring after today)
         * @param {GetFutureCalendarEventsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFutureCalendarEvents: async (acceptLanguage: GetFutureCalendarEventsAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getFutureCalendarEvents', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/calendar-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEventApi - functional programming interface
 * @export
 */
export const CalendarEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get future calendar events (occurring after today)
         * @param {GetFutureCalendarEventsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFutureCalendarEvents(acceptLanguage: GetFutureCalendarEventsAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFutureCalendarEvents(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarEventApi - factory interface
 * @export
 */
export const CalendarEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarEventApiFp(configuration)
    return {
        /**
         * 
         * @summary Get future calendar events (occurring after today)
         * @param {GetFutureCalendarEventsAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFutureCalendarEvents(acceptLanguage: GetFutureCalendarEventsAcceptLanguageEnum, options?: any): AxiosPromise<Array<CalendarEvent>> {
            return localVarFp.getFutureCalendarEvents(acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarEventApi - object-oriented interface
 * @export
 * @class CalendarEventApi
 * @extends {BaseAPI}
 */
export class CalendarEventApi extends BaseAPI {
    /**
     * 
     * @summary Get future calendar events (occurring after today)
     * @param {GetFutureCalendarEventsAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public getFutureCalendarEvents(acceptLanguage: GetFutureCalendarEventsAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return CalendarEventApiFp(this.configuration).getFutureCalendarEvents(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetFutureCalendarEventsAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetFutureCalendarEventsAcceptLanguageEnum = typeof GetFutureCalendarEventsAcceptLanguageEnum[keyof typeof GetFutureCalendarEventsAcceptLanguageEnum];


/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new client in the application and with the identity provider
         * @param {CreateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPost} clientPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient: async (acceptLanguage: CreateClientAcceptLanguageEnum, clientPost: ClientPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('createClient', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'clientPost' is not null or undefined
            assertParamExists('createClient', 'clientPost', clientPost)
            const localVarPath = `/api/v1.0/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all advisors for a client
         * @param {number} id 
         * @param {GetAllAdvisorsForClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdvisorsForClient: async (id: number, acceptLanguage: GetAllAdvisorsForClientAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllAdvisorsForClient', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getAllAdvisorsForClient', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/client/{id}/advisors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a client
         * @param {number} id 
         * @param {GetClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient: async (id: number, acceptLanguage: GetClientAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClient', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getClient', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a client
         * @param {GetClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMe: async (acceptLanguage: GetClientMeAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getClientMe', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/client/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search client
         * @param {SearchClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientSearchQuery} clientSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClient: async (acceptLanguage: SearchClientAcceptLanguageEnum, clientSearchQuery: ClientSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClient', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'clientSearchQuery' is not null or undefined
            assertParamExists('searchClient', 'clientSearchQuery', clientSearchQuery)
            const localVarPath = `/api/v1.0/client/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search mortgages of a client
         * @param {number} id 
         * @param {SearchClientMortgageAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageSearchQuery} mortgageSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientMortgage: async (id: number, acceptLanguage: SearchClientMortgageAcceptLanguageEnum, mortgageSearchQuery: MortgageSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientMortgage', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientMortgage', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'mortgageSearchQuery' is not null or undefined
            assertParamExists('searchClientMortgage', 'mortgageSearchQuery', mortgageSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/mortgages/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortgageSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client
         * @param {number} id 
         * @param {UpdateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPut} clientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (id: number, acceptLanguage: UpdateClientAcceptLanguageEnum, clientPut: ClientPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClient', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('updateClient', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'clientPut' is not null or undefined
            assertParamExists('updateClient', 'clientPut', clientPut)
            const localVarPath = `/api/v1.0/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update my profile
         * @param {UpdateClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {SimpleClientPut} simpleClientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientMe: async (acceptLanguage: UpdateClientMeAcceptLanguageEnum, simpleClientPut: SimpleClientPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('updateClientMe', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'simpleClientPut' is not null or undefined
            assertParamExists('updateClientMe', 'simpleClientPut', simpleClientPut)
            const localVarPath = `/api/v1.0/client/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleClientPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new client in the application and with the identity provider
         * @param {CreateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPost} clientPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClient(acceptLanguage: CreateClientAcceptLanguageEnum, clientPost: ClientPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(acceptLanguage, clientPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all advisors for a client
         * @param {number} id 
         * @param {GetAllAdvisorsForClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdvisorsForClient(id: number, acceptLanguage: GetAllAdvisorsForClientAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Advisor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAdvisorsForClient(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a client
         * @param {number} id 
         * @param {GetClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClient(id: number, acceptLanguage: GetClientAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClient(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a client
         * @param {GetClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientMe(acceptLanguage: GetClientMeAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientMe(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search client
         * @param {SearchClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientSearchQuery} clientSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClient(acceptLanguage: SearchClientAcceptLanguageEnum, clientSearchQuery: ClientSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClient(acceptLanguage, clientSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search mortgages of a client
         * @param {number} id 
         * @param {SearchClientMortgageAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageSearchQuery} mortgageSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientMortgage(id: number, acceptLanguage: SearchClientMortgageAcceptLanguageEnum, mortgageSearchQuery: MortgageSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientMortgage(id, acceptLanguage, mortgageSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client
         * @param {number} id 
         * @param {UpdateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPut} clientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(id: number, acceptLanguage: UpdateClientAcceptLanguageEnum, clientPut: ClientPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(id, acceptLanguage, clientPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update my profile
         * @param {UpdateClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {SimpleClientPut} simpleClientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientMe(acceptLanguage: UpdateClientMeAcceptLanguageEnum, simpleClientPut: SimpleClientPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientMe(acceptLanguage, simpleClientPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new client in the application and with the identity provider
         * @param {CreateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPost} clientPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(acceptLanguage: CreateClientAcceptLanguageEnum, clientPost: ClientPost, options?: any): AxiosPromise<Client> {
            return localVarFp.createClient(acceptLanguage, clientPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all advisors for a client
         * @param {number} id 
         * @param {GetAllAdvisorsForClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdvisorsForClient(id: number, acceptLanguage: GetAllAdvisorsForClientAcceptLanguageEnum, options?: any): AxiosPromise<Array<Advisor>> {
            return localVarFp.getAllAdvisorsForClient(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a client
         * @param {number} id 
         * @param {GetClientAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient(id: number, acceptLanguage: GetClientAcceptLanguageEnum, options?: any): AxiosPromise<Client> {
            return localVarFp.getClient(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a client
         * @param {GetClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientMe(acceptLanguage: GetClientMeAcceptLanguageEnum, options?: any): AxiosPromise<Client> {
            return localVarFp.getClientMe(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search client
         * @param {SearchClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientSearchQuery} clientSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClient(acceptLanguage: SearchClientAcceptLanguageEnum, clientSearchQuery: ClientSearchQuery, options?: any): AxiosPromise<ClientSearchResult> {
            return localVarFp.searchClient(acceptLanguage, clientSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search mortgages of a client
         * @param {number} id 
         * @param {SearchClientMortgageAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageSearchQuery} mortgageSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientMortgage(id: number, acceptLanguage: SearchClientMortgageAcceptLanguageEnum, mortgageSearchQuery: MortgageSearchQuery, options?: any): AxiosPromise<MortgageSearchResult> {
            return localVarFp.searchClientMortgage(id, acceptLanguage, mortgageSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client
         * @param {number} id 
         * @param {UpdateClientAcceptLanguageEnum} acceptLanguage Language
         * @param {ClientPut} clientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(id: number, acceptLanguage: UpdateClientAcceptLanguageEnum, clientPut: ClientPut, options?: any): AxiosPromise<Client> {
            return localVarFp.updateClient(id, acceptLanguage, clientPut, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update my profile
         * @param {UpdateClientMeAcceptLanguageEnum} acceptLanguage Language
         * @param {SimpleClientPut} simpleClientPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientMe(acceptLanguage: UpdateClientMeAcceptLanguageEnum, simpleClientPut: SimpleClientPut, options?: any): AxiosPromise<Client> {
            return localVarFp.updateClientMe(acceptLanguage, simpleClientPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @summary Create a new client in the application and with the identity provider
     * @param {CreateClientAcceptLanguageEnum} acceptLanguage Language
     * @param {ClientPost} clientPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClient(acceptLanguage: CreateClientAcceptLanguageEnum, clientPost: ClientPost, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClient(acceptLanguage, clientPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all advisors for a client
     * @param {number} id 
     * @param {GetAllAdvisorsForClientAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getAllAdvisorsForClient(id: number, acceptLanguage: GetAllAdvisorsForClientAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getAllAdvisorsForClient(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a client
     * @param {number} id 
     * @param {GetClientAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClient(id: number, acceptLanguage: GetClientAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClient(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a client
     * @param {GetClientMeAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientMe(acceptLanguage: GetClientMeAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientMe(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search client
     * @param {SearchClientAcceptLanguageEnum} acceptLanguage Language
     * @param {ClientSearchQuery} clientSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public searchClient(acceptLanguage: SearchClientAcceptLanguageEnum, clientSearchQuery: ClientSearchQuery, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).searchClient(acceptLanguage, clientSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search mortgages of a client
     * @param {number} id 
     * @param {SearchClientMortgageAcceptLanguageEnum} acceptLanguage Language
     * @param {MortgageSearchQuery} mortgageSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public searchClientMortgage(id: number, acceptLanguage: SearchClientMortgageAcceptLanguageEnum, mortgageSearchQuery: MortgageSearchQuery, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).searchClientMortgage(id, acceptLanguage, mortgageSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client
     * @param {number} id 
     * @param {UpdateClientAcceptLanguageEnum} acceptLanguage Language
     * @param {ClientPut} clientPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClient(id: number, acceptLanguage: UpdateClientAcceptLanguageEnum, clientPut: ClientPut, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClient(id, acceptLanguage, clientPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update my profile
     * @param {UpdateClientMeAcceptLanguageEnum} acceptLanguage Language
     * @param {SimpleClientPut} simpleClientPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientMe(acceptLanguage: UpdateClientMeAcceptLanguageEnum, simpleClientPut: SimpleClientPut, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientMe(acceptLanguage, simpleClientPut, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CreateClientAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type CreateClientAcceptLanguageEnum = typeof CreateClientAcceptLanguageEnum[keyof typeof CreateClientAcceptLanguageEnum];
/**
 * @export
 */
export const GetAllAdvisorsForClientAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetAllAdvisorsForClientAcceptLanguageEnum = typeof GetAllAdvisorsForClientAcceptLanguageEnum[keyof typeof GetAllAdvisorsForClientAcceptLanguageEnum];
/**
 * @export
 */
export const GetClientAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetClientAcceptLanguageEnum = typeof GetClientAcceptLanguageEnum[keyof typeof GetClientAcceptLanguageEnum];
/**
 * @export
 */
export const GetClientMeAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetClientMeAcceptLanguageEnum = typeof GetClientMeAcceptLanguageEnum[keyof typeof GetClientMeAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientAcceptLanguageEnum = typeof SearchClientAcceptLanguageEnum[keyof typeof SearchClientAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientMortgageAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientMortgageAcceptLanguageEnum = typeof SearchClientMortgageAcceptLanguageEnum[keyof typeof SearchClientMortgageAcceptLanguageEnum];
/**
 * @export
 */
export const UpdateClientAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type UpdateClientAcceptLanguageEnum = typeof UpdateClientAcceptLanguageEnum[keyof typeof UpdateClientAcceptLanguageEnum];
/**
 * @export
 */
export const UpdateClientMeAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type UpdateClientMeAcceptLanguageEnum = typeof UpdateClientMeAcceptLanguageEnum[keyof typeof UpdateClientMeAcceptLanguageEnum];


/**
 * ClientDocumentsApi - axios parameter creator
 * @export
 */
export const ClientDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a document of a client
         * @param {number} id 
         * @param {string} filename 
         * @param {DeleteAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, filename: string, acceptLanguage: DeleteAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('_delete', 'filename', filename)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('_delete', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/client/{id}/documents/{filename}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a document
         * @param {number} id 
         * @param {string} filename 
         * @param {DownloadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument: async (id: number, filename: string, acceptLanguage: DownloadDocumentAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDocument', 'id', id)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('downloadDocument', 'filename', filename)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('downloadDocument', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/client/{id}/documents/{filename}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search documents of a client
         * @param {number} id 
         * @param {SearchClientDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {DocumentSearchQuery} documentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientDocument: async (id: number, acceptLanguage: SearchClientDocumentAcceptLanguageEnum, documentSearchQuery: DocumentSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientDocument', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientDocument', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'documentSearchQuery' is not null or undefined
            assertParamExists('searchClientDocument', 'documentSearchQuery', documentSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search insurance policy documents of a client
         * @param {number} id 
         * @param {SearchClientInsurancePolicyDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {InsurancePolicyDocumentSearchQuery} insurancePolicyDocumentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientInsurancePolicyDocument: async (id: number, acceptLanguage: SearchClientInsurancePolicyDocumentAcceptLanguageEnum, insurancePolicyDocumentSearchQuery: InsurancePolicyDocumentSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientInsurancePolicyDocument', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientInsurancePolicyDocument', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'insurancePolicyDocumentSearchQuery' is not null or undefined
            assertParamExists('searchClientInsurancePolicyDocument', 'insurancePolicyDocumentSearchQuery', insurancePolicyDocumentSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/insurance-policy-documents/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insurancePolicyDocumentSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search mortgage statements of a client
         * @param {number} id 
         * @param {SearchClientMortgageStatementAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageStatementSearchQuery} mortgageStatementSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientMortgageStatement: async (id: number, acceptLanguage: SearchClientMortgageStatementAcceptLanguageEnum, mortgageStatementSearchQuery: MortgageStatementSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientMortgageStatement', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientMortgageStatement', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'mortgageStatementSearchQuery' is not null or undefined
            assertParamExists('searchClientMortgageStatement', 'mortgageStatementSearchQuery', mortgageStatementSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/mortgage-statements/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortgageStatementSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search notice of assessments of a client
         * @param {number} id 
         * @param {SearchClientNoticeOfAssessmentAcceptLanguageEnum} acceptLanguage Language
         * @param {NoticeOfAssessmentSearchQuery} noticeOfAssessmentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientNoticeOfAssessment: async (id: number, acceptLanguage: SearchClientNoticeOfAssessmentAcceptLanguageEnum, noticeOfAssessmentSearchQuery: NoticeOfAssessmentSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientNoticeOfAssessment', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientNoticeOfAssessment', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'noticeOfAssessmentSearchQuery' is not null or undefined
            assertParamExists('searchClientNoticeOfAssessment', 'noticeOfAssessmentSearchQuery', noticeOfAssessmentSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/notice-of-assessments/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeOfAssessmentSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search RRSP Contributions of a client
         * @param {number} id 
         * @param {SearchClientRRSPContributionAcceptLanguageEnum} acceptLanguage Language
         * @param {RRSPContributionSearchQuery} rRSPContributionSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientRRSPContribution: async (id: number, acceptLanguage: SearchClientRRSPContributionAcceptLanguageEnum, rRSPContributionSearchQuery: RRSPContributionSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientRRSPContribution', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientRRSPContribution', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'rRSPContributionSearchQuery' is not null or undefined
            assertParamExists('searchClientRRSPContribution', 'rRSPContributionSearchQuery', rRSPContributionSearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/rrsp-contributions/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rRSPContributionSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search T1s of a client
         * @param {number} id 
         * @param {SearchClientT1AcceptLanguageEnum} acceptLanguage Language
         * @param {T1SearchQuery} t1SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientT1: async (id: number, acceptLanguage: SearchClientT1AcceptLanguageEnum, t1SearchQuery: T1SearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientT1', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientT1', 'acceptLanguage', acceptLanguage)
            // verify required parameter 't1SearchQuery' is not null or undefined
            assertParamExists('searchClientT1', 't1SearchQuery', t1SearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/t1s/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(t1SearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search T4s of a client
         * @param {number} id 
         * @param {SearchClientT4AcceptLanguageEnum} acceptLanguage Language
         * @param {T4SearchQuery} t4SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientT4: async (id: number, acceptLanguage: SearchClientT4AcceptLanguageEnum, t4SearchQuery: T4SearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchClientT4', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchClientT4', 'acceptLanguage', acceptLanguage)
            // verify required parameter 't4SearchQuery' is not null or undefined
            assertParamExists('searchClientT4', 't4SearchQuery', t4SearchQuery)
            const localVarPath = `/api/v1.0/client/{id}/documents/t4s/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(t4SearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a document
         * @param {number} id 
         * @param {UploadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {any} info 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (id: number, acceptLanguage: UploadDocumentAcceptLanguageEnum, info: any, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadDocument', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('uploadDocument', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'info' is not null or undefined
            assertParamExists('uploadDocument', 'info', info)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocument', 'file', file)
            const localVarPath = `/api/v1.0/client/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


            if (info !== undefined) { 
                localVarFormParams.append('info', info as any);
            }

            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientDocumentsApi - functional programming interface
 * @export
 */
export const ClientDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a document of a client
         * @param {number} id 
         * @param {string} filename 
         * @param {DeleteAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, filename: string, acceptLanguage: DeleteAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, filename, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download a document
         * @param {number} id 
         * @param {string} filename 
         * @param {DownloadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocument(id: number, filename: string, acceptLanguage: DownloadDocumentAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocument(id, filename, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search documents of a client
         * @param {number} id 
         * @param {SearchClientDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {DocumentSearchQuery} documentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientDocument(id: number, acceptLanguage: SearchClientDocumentAcceptLanguageEnum, documentSearchQuery: DocumentSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientDocument(id, acceptLanguage, documentSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search insurance policy documents of a client
         * @param {number} id 
         * @param {SearchClientInsurancePolicyDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {InsurancePolicyDocumentSearchQuery} insurancePolicyDocumentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientInsurancePolicyDocument(id: number, acceptLanguage: SearchClientInsurancePolicyDocumentAcceptLanguageEnum, insurancePolicyDocumentSearchQuery: InsurancePolicyDocumentSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurancePolicyDocumentSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientInsurancePolicyDocument(id, acceptLanguage, insurancePolicyDocumentSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search mortgage statements of a client
         * @param {number} id 
         * @param {SearchClientMortgageStatementAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageStatementSearchQuery} mortgageStatementSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientMortgageStatement(id: number, acceptLanguage: SearchClientMortgageStatementAcceptLanguageEnum, mortgageStatementSearchQuery: MortgageStatementSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageStatementSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientMortgageStatement(id, acceptLanguage, mortgageStatementSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search notice of assessments of a client
         * @param {number} id 
         * @param {SearchClientNoticeOfAssessmentAcceptLanguageEnum} acceptLanguage Language
         * @param {NoticeOfAssessmentSearchQuery} noticeOfAssessmentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientNoticeOfAssessment(id: number, acceptLanguage: SearchClientNoticeOfAssessmentAcceptLanguageEnum, noticeOfAssessmentSearchQuery: NoticeOfAssessmentSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeOfAssessmentSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientNoticeOfAssessment(id, acceptLanguage, noticeOfAssessmentSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search RRSP Contributions of a client
         * @param {number} id 
         * @param {SearchClientRRSPContributionAcceptLanguageEnum} acceptLanguage Language
         * @param {RRSPContributionSearchQuery} rRSPContributionSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientRRSPContribution(id: number, acceptLanguage: SearchClientRRSPContributionAcceptLanguageEnum, rRSPContributionSearchQuery: RRSPContributionSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RRSPContributionSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientRRSPContribution(id, acceptLanguage, rRSPContributionSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search T1s of a client
         * @param {number} id 
         * @param {SearchClientT1AcceptLanguageEnum} acceptLanguage Language
         * @param {T1SearchQuery} t1SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientT1(id: number, acceptLanguage: SearchClientT1AcceptLanguageEnum, t1SearchQuery: T1SearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<T1SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientT1(id, acceptLanguage, t1SearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search T4s of a client
         * @param {number} id 
         * @param {SearchClientT4AcceptLanguageEnum} acceptLanguage Language
         * @param {T4SearchQuery} t4SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClientT4(id: number, acceptLanguage: SearchClientT4AcceptLanguageEnum, t4SearchQuery: T4SearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<T4SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientT4(id, acceptLanguage, t4SearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a document
         * @param {number} id 
         * @param {UploadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {any} info 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(id: number, acceptLanguage: UploadDocumentAcceptLanguageEnum, info: any, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(id, acceptLanguage, info, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientDocumentsApi - factory interface
 * @export
 */
export const ClientDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a document of a client
         * @param {number} id 
         * @param {string} filename 
         * @param {DeleteAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, filename: string, acceptLanguage: DeleteAcceptLanguageEnum, options?: any): AxiosPromise<object> {
            return localVarFp._delete(id, filename, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a document
         * @param {number} id 
         * @param {string} filename 
         * @param {DownloadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument(id: number, filename: string, acceptLanguage: DownloadDocumentAcceptLanguageEnum, options?: any): AxiosPromise<File> {
            return localVarFp.downloadDocument(id, filename, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search documents of a client
         * @param {number} id 
         * @param {SearchClientDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {DocumentSearchQuery} documentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientDocument(id: number, acceptLanguage: SearchClientDocumentAcceptLanguageEnum, documentSearchQuery: DocumentSearchQuery, options?: any): AxiosPromise<DocumentSearchResult> {
            return localVarFp.searchClientDocument(id, acceptLanguage, documentSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search insurance policy documents of a client
         * @param {number} id 
         * @param {SearchClientInsurancePolicyDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {InsurancePolicyDocumentSearchQuery} insurancePolicyDocumentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientInsurancePolicyDocument(id: number, acceptLanguage: SearchClientInsurancePolicyDocumentAcceptLanguageEnum, insurancePolicyDocumentSearchQuery: InsurancePolicyDocumentSearchQuery, options?: any): AxiosPromise<InsurancePolicyDocumentSearchResult> {
            return localVarFp.searchClientInsurancePolicyDocument(id, acceptLanguage, insurancePolicyDocumentSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search mortgage statements of a client
         * @param {number} id 
         * @param {SearchClientMortgageStatementAcceptLanguageEnum} acceptLanguage Language
         * @param {MortgageStatementSearchQuery} mortgageStatementSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientMortgageStatement(id: number, acceptLanguage: SearchClientMortgageStatementAcceptLanguageEnum, mortgageStatementSearchQuery: MortgageStatementSearchQuery, options?: any): AxiosPromise<MortgageStatementSearchResult> {
            return localVarFp.searchClientMortgageStatement(id, acceptLanguage, mortgageStatementSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search notice of assessments of a client
         * @param {number} id 
         * @param {SearchClientNoticeOfAssessmentAcceptLanguageEnum} acceptLanguage Language
         * @param {NoticeOfAssessmentSearchQuery} noticeOfAssessmentSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientNoticeOfAssessment(id: number, acceptLanguage: SearchClientNoticeOfAssessmentAcceptLanguageEnum, noticeOfAssessmentSearchQuery: NoticeOfAssessmentSearchQuery, options?: any): AxiosPromise<NoticeOfAssessmentSearchResult> {
            return localVarFp.searchClientNoticeOfAssessment(id, acceptLanguage, noticeOfAssessmentSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search RRSP Contributions of a client
         * @param {number} id 
         * @param {SearchClientRRSPContributionAcceptLanguageEnum} acceptLanguage Language
         * @param {RRSPContributionSearchQuery} rRSPContributionSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientRRSPContribution(id: number, acceptLanguage: SearchClientRRSPContributionAcceptLanguageEnum, rRSPContributionSearchQuery: RRSPContributionSearchQuery, options?: any): AxiosPromise<RRSPContributionSearchResult> {
            return localVarFp.searchClientRRSPContribution(id, acceptLanguage, rRSPContributionSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search T1s of a client
         * @param {number} id 
         * @param {SearchClientT1AcceptLanguageEnum} acceptLanguage Language
         * @param {T1SearchQuery} t1SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientT1(id: number, acceptLanguage: SearchClientT1AcceptLanguageEnum, t1SearchQuery: T1SearchQuery, options?: any): AxiosPromise<T1SearchResult> {
            return localVarFp.searchClientT1(id, acceptLanguage, t1SearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search T4s of a client
         * @param {number} id 
         * @param {SearchClientT4AcceptLanguageEnum} acceptLanguage Language
         * @param {T4SearchQuery} t4SearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClientT4(id: number, acceptLanguage: SearchClientT4AcceptLanguageEnum, t4SearchQuery: T4SearchQuery, options?: any): AxiosPromise<T4SearchResult> {
            return localVarFp.searchClientT4(id, acceptLanguage, t4SearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a document
         * @param {number} id 
         * @param {UploadDocumentAcceptLanguageEnum} acceptLanguage Language
         * @param {any} info 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(id: number, acceptLanguage: UploadDocumentAcceptLanguageEnum, info: any, file: any, options?: any): AxiosPromise<Document> {
            return localVarFp.uploadDocument(id, acceptLanguage, info, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientDocumentsApi - object-oriented interface
 * @export
 * @class ClientDocumentsApi
 * @extends {BaseAPI}
 */
export class ClientDocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a document of a client
     * @param {number} id 
     * @param {string} filename 
     * @param {DeleteAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public _delete(id: number, filename: string, acceptLanguage: DeleteAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration)._delete(id, filename, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a document
     * @param {number} id 
     * @param {string} filename 
     * @param {DownloadDocumentAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public downloadDocument(id: number, filename: string, acceptLanguage: DownloadDocumentAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).downloadDocument(id, filename, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search documents of a client
     * @param {number} id 
     * @param {SearchClientDocumentAcceptLanguageEnum} acceptLanguage Language
     * @param {DocumentSearchQuery} documentSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientDocument(id: number, acceptLanguage: SearchClientDocumentAcceptLanguageEnum, documentSearchQuery: DocumentSearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientDocument(id, acceptLanguage, documentSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search insurance policy documents of a client
     * @param {number} id 
     * @param {SearchClientInsurancePolicyDocumentAcceptLanguageEnum} acceptLanguage Language
     * @param {InsurancePolicyDocumentSearchQuery} insurancePolicyDocumentSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientInsurancePolicyDocument(id: number, acceptLanguage: SearchClientInsurancePolicyDocumentAcceptLanguageEnum, insurancePolicyDocumentSearchQuery: InsurancePolicyDocumentSearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientInsurancePolicyDocument(id, acceptLanguage, insurancePolicyDocumentSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search mortgage statements of a client
     * @param {number} id 
     * @param {SearchClientMortgageStatementAcceptLanguageEnum} acceptLanguage Language
     * @param {MortgageStatementSearchQuery} mortgageStatementSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientMortgageStatement(id: number, acceptLanguage: SearchClientMortgageStatementAcceptLanguageEnum, mortgageStatementSearchQuery: MortgageStatementSearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientMortgageStatement(id, acceptLanguage, mortgageStatementSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search notice of assessments of a client
     * @param {number} id 
     * @param {SearchClientNoticeOfAssessmentAcceptLanguageEnum} acceptLanguage Language
     * @param {NoticeOfAssessmentSearchQuery} noticeOfAssessmentSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientNoticeOfAssessment(id: number, acceptLanguage: SearchClientNoticeOfAssessmentAcceptLanguageEnum, noticeOfAssessmentSearchQuery: NoticeOfAssessmentSearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientNoticeOfAssessment(id, acceptLanguage, noticeOfAssessmentSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search RRSP Contributions of a client
     * @param {number} id 
     * @param {SearchClientRRSPContributionAcceptLanguageEnum} acceptLanguage Language
     * @param {RRSPContributionSearchQuery} rRSPContributionSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientRRSPContribution(id: number, acceptLanguage: SearchClientRRSPContributionAcceptLanguageEnum, rRSPContributionSearchQuery: RRSPContributionSearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientRRSPContribution(id, acceptLanguage, rRSPContributionSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search T1s of a client
     * @param {number} id 
     * @param {SearchClientT1AcceptLanguageEnum} acceptLanguage Language
     * @param {T1SearchQuery} t1SearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientT1(id: number, acceptLanguage: SearchClientT1AcceptLanguageEnum, t1SearchQuery: T1SearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientT1(id, acceptLanguage, t1SearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search T4s of a client
     * @param {number} id 
     * @param {SearchClientT4AcceptLanguageEnum} acceptLanguage Language
     * @param {T4SearchQuery} t4SearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public searchClientT4(id: number, acceptLanguage: SearchClientT4AcceptLanguageEnum, t4SearchQuery: T4SearchQuery, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).searchClientT4(id, acceptLanguage, t4SearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a document
     * @param {number} id 
     * @param {UploadDocumentAcceptLanguageEnum} acceptLanguage Language
     * @param {any} info 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDocumentsApi
     */
    public uploadDocument(id: number, acceptLanguage: UploadDocumentAcceptLanguageEnum, info: any, file: any, options?: AxiosRequestConfig) {
        return ClientDocumentsApiFp(this.configuration).uploadDocument(id, acceptLanguage, info, file, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DeleteAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type DeleteAcceptLanguageEnum = typeof DeleteAcceptLanguageEnum[keyof typeof DeleteAcceptLanguageEnum];
/**
 * @export
 */
export const DownloadDocumentAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type DownloadDocumentAcceptLanguageEnum = typeof DownloadDocumentAcceptLanguageEnum[keyof typeof DownloadDocumentAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientDocumentAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientDocumentAcceptLanguageEnum = typeof SearchClientDocumentAcceptLanguageEnum[keyof typeof SearchClientDocumentAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientInsurancePolicyDocumentAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientInsurancePolicyDocumentAcceptLanguageEnum = typeof SearchClientInsurancePolicyDocumentAcceptLanguageEnum[keyof typeof SearchClientInsurancePolicyDocumentAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientMortgageStatementAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientMortgageStatementAcceptLanguageEnum = typeof SearchClientMortgageStatementAcceptLanguageEnum[keyof typeof SearchClientMortgageStatementAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientNoticeOfAssessmentAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientNoticeOfAssessmentAcceptLanguageEnum = typeof SearchClientNoticeOfAssessmentAcceptLanguageEnum[keyof typeof SearchClientNoticeOfAssessmentAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientRRSPContributionAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientRRSPContributionAcceptLanguageEnum = typeof SearchClientRRSPContributionAcceptLanguageEnum[keyof typeof SearchClientRRSPContributionAcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientT1AcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientT1AcceptLanguageEnum = typeof SearchClientT1AcceptLanguageEnum[keyof typeof SearchClientT1AcceptLanguageEnum];
/**
 * @export
 */
export const SearchClientT4AcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchClientT4AcceptLanguageEnum = typeof SearchClientT4AcceptLanguageEnum[keyof typeof SearchClientT4AcceptLanguageEnum];
/**
 * @export
 */
export const UploadDocumentAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type UploadDocumentAcceptLanguageEnum = typeof UploadDocumentAcceptLanguageEnum[keyof typeof UploadDocumentAcceptLanguageEnum];


/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send an email to request an appointment
         * @param {SendAppointmentRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {AppointmentEmailPost} appointmentEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppointmentRequestEmail: async (acceptLanguage: SendAppointmentRequestEmailAcceptLanguageEnum, appointmentEmailPost: AppointmentEmailPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendAppointmentRequestEmail', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'appointmentEmailPost' is not null or undefined
            assertParamExists('sendAppointmentRequestEmail', 'appointmentEmailPost', appointmentEmailPost)
            const localVarPath = `/api/v1.0/email/appointment-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentEmailPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an email to request a pre-authorized contribution (PAC) to an account.
         * @param {SendPACRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PACRequestEmailPost} pACRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPACRequestEmail: async (acceptLanguage: SendPACRequestEmailAcceptLanguageEnum, pACRequestEmailPost: PACRequestEmailPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendPACRequestEmail', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'pACRequestEmailPost' is not null or undefined
            assertParamExists('sendPACRequestEmail', 'pACRequestEmailPost', pACRequestEmailPost)
            const localVarPath = `/api/v1.0/email/pac`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pACRequestEmailPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an email to request some action to be performed on the portfolio (Add/Update/Delete account).
         * @param {SendPortfolioRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PortfolioRequestEmailPost} portfolioRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPortfolioRequestEmail: async (acceptLanguage: SendPortfolioRequestEmailAcceptLanguageEnum, portfolioRequestEmailPost: PortfolioRequestEmailPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendPortfolioRequestEmail', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'portfolioRequestEmailPost' is not null or undefined
            assertParamExists('sendPortfolioRequestEmail', 'portfolioRequestEmailPost', portfolioRequestEmailPost)
            const localVarPath = `/api/v1.0/email/portfolio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioRequestEmailPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an email to ask a question
         * @param {SendQuestionEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {QuestionEmailPost} questionEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQuestionEmail: async (acceptLanguage: SendQuestionEmailAcceptLanguageEnum, questionEmailPost: QuestionEmailPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendQuestionEmail', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'questionEmailPost' is not null or undefined
            assertParamExists('sendQuestionEmail', 'questionEmailPost', questionEmailPost)
            const localVarPath = `/api/v1.0/email/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionEmailPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an email to request a withdrawal from an account.
         * @param {SendWithdrawalRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {WithdrawalRequestEmailPost} withdrawalRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWithdrawalRequestEmail: async (acceptLanguage: SendWithdrawalRequestEmailAcceptLanguageEnum, withdrawalRequestEmailPost: WithdrawalRequestEmailPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendWithdrawalRequestEmail', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'withdrawalRequestEmailPost' is not null or undefined
            assertParamExists('sendWithdrawalRequestEmail', 'withdrawalRequestEmailPost', withdrawalRequestEmailPost)
            const localVarPath = `/api/v1.0/email/withdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawalRequestEmailPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send an email to request an appointment
         * @param {SendAppointmentRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {AppointmentEmailPost} appointmentEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAppointmentRequestEmail(acceptLanguage: SendAppointmentRequestEmailAcceptLanguageEnum, appointmentEmailPost: AppointmentEmailPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAppointmentRequestEmail(acceptLanguage, appointmentEmailPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an email to request a pre-authorized contribution (PAC) to an account.
         * @param {SendPACRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PACRequestEmailPost} pACRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPACRequestEmail(acceptLanguage: SendPACRequestEmailAcceptLanguageEnum, pACRequestEmailPost: PACRequestEmailPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPACRequestEmail(acceptLanguage, pACRequestEmailPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an email to request some action to be performed on the portfolio (Add/Update/Delete account).
         * @param {SendPortfolioRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PortfolioRequestEmailPost} portfolioRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPortfolioRequestEmail(acceptLanguage: SendPortfolioRequestEmailAcceptLanguageEnum, portfolioRequestEmailPost: PortfolioRequestEmailPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPortfolioRequestEmail(acceptLanguage, portfolioRequestEmailPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an email to ask a question
         * @param {SendQuestionEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {QuestionEmailPost} questionEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendQuestionEmail(acceptLanguage: SendQuestionEmailAcceptLanguageEnum, questionEmailPost: QuestionEmailPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendQuestionEmail(acceptLanguage, questionEmailPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an email to request a withdrawal from an account.
         * @param {SendWithdrawalRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {WithdrawalRequestEmailPost} withdrawalRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendWithdrawalRequestEmail(acceptLanguage: SendWithdrawalRequestEmailAcceptLanguageEnum, withdrawalRequestEmailPost: WithdrawalRequestEmailPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendWithdrawalRequestEmail(acceptLanguage, withdrawalRequestEmailPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Send an email to request an appointment
         * @param {SendAppointmentRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {AppointmentEmailPost} appointmentEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAppointmentRequestEmail(acceptLanguage: SendAppointmentRequestEmailAcceptLanguageEnum, appointmentEmailPost: AppointmentEmailPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendAppointmentRequestEmail(acceptLanguage, appointmentEmailPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an email to request a pre-authorized contribution (PAC) to an account.
         * @param {SendPACRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PACRequestEmailPost} pACRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPACRequestEmail(acceptLanguage: SendPACRequestEmailAcceptLanguageEnum, pACRequestEmailPost: PACRequestEmailPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendPACRequestEmail(acceptLanguage, pACRequestEmailPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an email to request some action to be performed on the portfolio (Add/Update/Delete account).
         * @param {SendPortfolioRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {PortfolioRequestEmailPost} portfolioRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPortfolioRequestEmail(acceptLanguage: SendPortfolioRequestEmailAcceptLanguageEnum, portfolioRequestEmailPost: PortfolioRequestEmailPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendPortfolioRequestEmail(acceptLanguage, portfolioRequestEmailPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an email to ask a question
         * @param {SendQuestionEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {QuestionEmailPost} questionEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQuestionEmail(acceptLanguage: SendQuestionEmailAcceptLanguageEnum, questionEmailPost: QuestionEmailPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendQuestionEmail(acceptLanguage, questionEmailPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an email to request a withdrawal from an account.
         * @param {SendWithdrawalRequestEmailAcceptLanguageEnum} acceptLanguage Language
         * @param {WithdrawalRequestEmailPost} withdrawalRequestEmailPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWithdrawalRequestEmail(acceptLanguage: SendWithdrawalRequestEmailAcceptLanguageEnum, withdrawalRequestEmailPost: WithdrawalRequestEmailPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendWithdrawalRequestEmail(acceptLanguage, withdrawalRequestEmailPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary Send an email to request an appointment
     * @param {SendAppointmentRequestEmailAcceptLanguageEnum} acceptLanguage Language
     * @param {AppointmentEmailPost} appointmentEmailPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendAppointmentRequestEmail(acceptLanguage: SendAppointmentRequestEmailAcceptLanguageEnum, appointmentEmailPost: AppointmentEmailPost, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendAppointmentRequestEmail(acceptLanguage, appointmentEmailPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an email to request a pre-authorized contribution (PAC) to an account.
     * @param {SendPACRequestEmailAcceptLanguageEnum} acceptLanguage Language
     * @param {PACRequestEmailPost} pACRequestEmailPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendPACRequestEmail(acceptLanguage: SendPACRequestEmailAcceptLanguageEnum, pACRequestEmailPost: PACRequestEmailPost, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendPACRequestEmail(acceptLanguage, pACRequestEmailPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an email to request some action to be performed on the portfolio (Add/Update/Delete account).
     * @param {SendPortfolioRequestEmailAcceptLanguageEnum} acceptLanguage Language
     * @param {PortfolioRequestEmailPost} portfolioRequestEmailPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendPortfolioRequestEmail(acceptLanguage: SendPortfolioRequestEmailAcceptLanguageEnum, portfolioRequestEmailPost: PortfolioRequestEmailPost, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendPortfolioRequestEmail(acceptLanguage, portfolioRequestEmailPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an email to ask a question
     * @param {SendQuestionEmailAcceptLanguageEnum} acceptLanguage Language
     * @param {QuestionEmailPost} questionEmailPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendQuestionEmail(acceptLanguage: SendQuestionEmailAcceptLanguageEnum, questionEmailPost: QuestionEmailPost, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendQuestionEmail(acceptLanguage, questionEmailPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an email to request a withdrawal from an account.
     * @param {SendWithdrawalRequestEmailAcceptLanguageEnum} acceptLanguage Language
     * @param {WithdrawalRequestEmailPost} withdrawalRequestEmailPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendWithdrawalRequestEmail(acceptLanguage: SendWithdrawalRequestEmailAcceptLanguageEnum, withdrawalRequestEmailPost: WithdrawalRequestEmailPost, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendWithdrawalRequestEmail(acceptLanguage, withdrawalRequestEmailPost, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const SendAppointmentRequestEmailAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendAppointmentRequestEmailAcceptLanguageEnum = typeof SendAppointmentRequestEmailAcceptLanguageEnum[keyof typeof SendAppointmentRequestEmailAcceptLanguageEnum];
/**
 * @export
 */
export const SendPACRequestEmailAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendPACRequestEmailAcceptLanguageEnum = typeof SendPACRequestEmailAcceptLanguageEnum[keyof typeof SendPACRequestEmailAcceptLanguageEnum];
/**
 * @export
 */
export const SendPortfolioRequestEmailAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendPortfolioRequestEmailAcceptLanguageEnum = typeof SendPortfolioRequestEmailAcceptLanguageEnum[keyof typeof SendPortfolioRequestEmailAcceptLanguageEnum];
/**
 * @export
 */
export const SendQuestionEmailAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendQuestionEmailAcceptLanguageEnum = typeof SendQuestionEmailAcceptLanguageEnum[keyof typeof SendQuestionEmailAcceptLanguageEnum];
/**
 * @export
 */
export const SendWithdrawalRequestEmailAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendWithdrawalRequestEmailAcceptLanguageEnum = typeof SendWithdrawalRequestEmailAcceptLanguageEnum[keyof typeof SendWithdrawalRequestEmailAcceptLanguageEnum];


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get count for new notifications of the logged in user (client only).
         * @param {GetNotificationsCountAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsCount: async (acceptLanguage: GetNotificationsCountAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getNotificationsCount', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/notifications/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search notifications
         * @param {SearchMyNotificationsAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationSearchQuery} notificationSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyNotifications: async (acceptLanguage: SearchMyNotificationsAcceptLanguageEnum, notificationSearchQuery: NotificationSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchMyNotifications', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'notificationSearchQuery' is not null or undefined
            assertParamExists('searchMyNotifications', 'notificationSearchQuery', notificationSearchQuery)
            const localVarPath = `/api/v1.0/notifications/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification to one or more client(s).
         * @param {SendNotificationAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationPost} notificationPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (acceptLanguage: SendNotificationAcceptLanguageEnum, notificationPost: NotificationPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('sendNotification', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'notificationPost' is not null or undefined
            assertParamExists('sendNotification', 'notificationPost', notificationPost)
            const localVarPath = `/api/v1.0/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get count for new notifications of the logged in user (client only).
         * @param {GetNotificationsCountAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsCount(acceptLanguage: GetNotificationsCountAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsCount(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search notifications
         * @param {SearchMyNotificationsAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationSearchQuery} notificationSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMyNotifications(acceptLanguage: SearchMyNotificationsAcceptLanguageEnum, notificationSearchQuery: NotificationSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMyNotifications(acceptLanguage, notificationSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a notification to one or more client(s).
         * @param {SendNotificationAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationPost} notificationPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(acceptLanguage: SendNotificationAcceptLanguageEnum, notificationPost: NotificationPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(acceptLanguage, notificationPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get count for new notifications of the logged in user (client only).
         * @param {GetNotificationsCountAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsCount(acceptLanguage: GetNotificationsCountAcceptLanguageEnum, options?: any): AxiosPromise<number> {
            return localVarFp.getNotificationsCount(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search notifications
         * @param {SearchMyNotificationsAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationSearchQuery} notificationSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyNotifications(acceptLanguage: SearchMyNotificationsAcceptLanguageEnum, notificationSearchQuery: NotificationSearchQuery, options?: any): AxiosPromise<NotificationSearchResult> {
            return localVarFp.searchMyNotifications(acceptLanguage, notificationSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a notification to one or more client(s).
         * @param {SendNotificationAcceptLanguageEnum} acceptLanguage Language
         * @param {NotificationPost} notificationPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(acceptLanguage: SendNotificationAcceptLanguageEnum, notificationPost: NotificationPost, options?: any): AxiosPromise<string> {
            return localVarFp.sendNotification(acceptLanguage, notificationPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get count for new notifications of the logged in user (client only).
     * @param {GetNotificationsCountAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationsCount(acceptLanguage: GetNotificationsCountAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationsCount(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search notifications
     * @param {SearchMyNotificationsAcceptLanguageEnum} acceptLanguage Language
     * @param {NotificationSearchQuery} notificationSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public searchMyNotifications(acceptLanguage: SearchMyNotificationsAcceptLanguageEnum, notificationSearchQuery: NotificationSearchQuery, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).searchMyNotifications(acceptLanguage, notificationSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a notification to one or more client(s).
     * @param {SendNotificationAcceptLanguageEnum} acceptLanguage Language
     * @param {NotificationPost} notificationPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendNotification(acceptLanguage: SendNotificationAcceptLanguageEnum, notificationPost: NotificationPost, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).sendNotification(acceptLanguage, notificationPost, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetNotificationsCountAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type GetNotificationsCountAcceptLanguageEnum = typeof GetNotificationsCountAcceptLanguageEnum[keyof typeof GetNotificationsCountAcceptLanguageEnum];
/**
 * @export
 */
export const SearchMyNotificationsAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchMyNotificationsAcceptLanguageEnum = typeof SearchMyNotificationsAcceptLanguageEnum[keyof typeof SearchMyNotificationsAcceptLanguageEnum];
/**
 * @export
 */
export const SendNotificationAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SendNotificationAcceptLanguageEnum = typeof SendNotificationAcceptLanguageEnum[keyof typeof SendNotificationAcceptLanguageEnum];


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return current user profile\'s
         * @param {MeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (acceptLanguage: MeAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('me', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/profile/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search profile
         * @param {SearchProfileAcceptLanguageEnum} acceptLanguage Language
         * @param {ProfileSearchQuery} profileSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfile: async (acceptLanguage: SearchProfileAcceptLanguageEnum, profileSearchQuery: ProfileSearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('searchProfile', 'acceptLanguage', acceptLanguage)
            // verify required parameter 'profileSearchQuery' is not null or undefined
            assertParamExists('searchProfile', 'profileSearchQuery', profileSearchQuery)
            const localVarPath = `/api/v1.0/profile/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return current user profile\'s
         * @param {MeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(acceptLanguage: MeAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search profile
         * @param {SearchProfileAcceptLanguageEnum} acceptLanguage Language
         * @param {ProfileSearchQuery} profileSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProfile(acceptLanguage: SearchProfileAcceptLanguageEnum, profileSearchQuery: ProfileSearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProfile(acceptLanguage, profileSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Return current user profile\'s
         * @param {MeAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(acceptLanguage: MeAcceptLanguageEnum, options?: any): AxiosPromise<Profile> {
            return localVarFp.me(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search profile
         * @param {SearchProfileAcceptLanguageEnum} acceptLanguage Language
         * @param {ProfileSearchQuery} profileSearchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProfile(acceptLanguage: SearchProfileAcceptLanguageEnum, profileSearchQuery: ProfileSearchQuery, options?: any): AxiosPromise<ProfileSearchResult> {
            return localVarFp.searchProfile(acceptLanguage, profileSearchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Return current user profile\'s
     * @param {MeAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public me(acceptLanguage: MeAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).me(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search profile
     * @param {SearchProfileAcceptLanguageEnum} acceptLanguage Language
     * @param {ProfileSearchQuery} profileSearchQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public searchProfile(acceptLanguage: SearchProfileAcceptLanguageEnum, profileSearchQuery: ProfileSearchQuery, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).searchProfile(acceptLanguage, profileSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const MeAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type MeAcceptLanguageEnum = typeof MeAcceptLanguageEnum[keyof typeof MeAcceptLanguageEnum];
/**
 * @export
 */
export const SearchProfileAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type SearchProfileAcceptLanguageEnum = typeof SearchProfileAcceptLanguageEnum[keyof typeof SearchProfileAcceptLanguageEnum];


/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This operation test if the application is up.
         * @summary Ping
         * @param {PingAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (acceptLanguage: PingAcceptLanguageEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('ping', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1.0/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * This operation test if the application is up.
         * @summary Ping
         * @param {PingAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(acceptLanguage: PingAcceptLanguageEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * This operation test if the application is up.
         * @summary Ping
         * @param {PingAcceptLanguageEnum} acceptLanguage Language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(acceptLanguage: PingAcceptLanguageEnum, options?: any): AxiosPromise<ServerStatus> {
            return localVarFp.ping(acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * This operation test if the application is up.
     * @summary Ping
     * @param {PingAcceptLanguageEnum} acceptLanguage Language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public ping(acceptLanguage: PingAcceptLanguageEnum, options?: AxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).ping(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PingAcceptLanguageEnum = {
    Fr: 'fr',
    En: 'en'
} as const;
export type PingAcceptLanguageEnum = typeof PingAcceptLanguageEnum[keyof typeof PingAcceptLanguageEnum];


