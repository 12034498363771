import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Profile, RoleType } from './api'
import Drawer from './components/drawer'
import Footer from './components/footer'
import Header from './components/header'
import Loader from './components/loader'
import Main from './components/main'
import changeLanguage from './helpers/changeLanguage'
import onHandlePermission from './redux/actions/permission.action'
import { getUserProfile, getUserProfileSuccess } from './redux/actions/userProfil.action'
import { RootState, useAppDispatch } from './redux/store'
import MainRouter from './routes'
import handleAccessToken from './services/config'

export default function App() {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false)
  const [profile, setProfile] = React.useState<Profile | undefined>(undefined)
  const { getAccessTokenSilently } = useAuth0()
  handleAccessToken.setAccessTokenSilently(getAccessTokenSilently)
  const dispatch = useAppDispatch()
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)
  const allowed = useSelector((state: RootState) => state.permissionReducer.allowed)

  React.useEffect(() => {
    dispatch(getUserProfile())
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setProfile(res.data)
        changeLanguage(res.data.locale)
        dispatch(getUserProfileSuccess(res.data))
      })
      .catch(() => {
        dispatch(
          getUserProfileSuccess({
            id: 0,
            email: '',
            locale: 'fr',
            communicationLocale: 'fr',
          }),
        )
      })
  }, [dispatch])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const checkRoles = React.useCallback(
    (user?: Profile) => {
      const acceptedRoles = [RoleType.Client.toUpperCase()]

      if (user && user.roles && Array.isArray(user.roles)) {
        const usersRoles = user.roles as string[]
        const hasRoles = acceptedRoles.some((acceptedRole) => usersRoles.includes(acceptedRole))

        if (!hasRoles) {
          dispatch(onHandlePermission(false))
        } else {
          dispatch(onHandlePermission(true))
        }
      }
    },
    [dispatch],
  )

  React.useEffect(() => {
    if (profile) {
      checkRoles(profile)
    }
  }, [profile, checkRoles])

  return (
    <Box sx={{ display: 'flex' }}>
      <Loader loading={loading} />
      {allowed && <Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />}
      {allowed && <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
      <Main>
        <MainRouter />
      </Main>
      <Footer mobileOpen={mobileOpen} />
    </Box>
  )
}

;(window as any).foobar = 'really_fubar'; // prettier-ignore
